import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';

export interface CaseDetailsResponse {
	caseDetails: {
		is_add_on: number;
		facility_name: string;
		date: Date;
		duration: string;
		flipped_indicator: boolean;
		patient_in: string;
		patient_out: string;
		procedure: string;
		surgeon: string;
		turnover: string;
		block_turnover: string;
		in_block_minutes: string;
		out_block_minutes: string;
		room: string;
	}[];
	panelMetrics: { name: string; value: number }[];
}

export interface CaseDetailsRequest {
	healthsystem_id: number | null;
	facility_id: number | null;
	block_detail_id: number | null;
	block_date: Date | null;
	utilization_type: string;
	wheels_turnover_threshold: string | null;
	procedures: string[];
}

/**
 * Provides endpoints for data values in database
 */
export const caseDetailsService = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getCaseDetails: build.query<CaseDetailsResponse, CaseDetailsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_CASE_DETAILS,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useGetCaseDetailsQuery } = caseDetailsService;

export const {
	endpoints: { getCaseDetails },
} = caseDetailsService;

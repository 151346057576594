import { LineSegment, Selection, VictoryAxis, VictoryBar, VictoryLabel, VictoryStack, VictoryTooltip } from 'victory';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	Accordion,
	Button,
	ChartLegend,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import {
	BlockScheduleItem,
	ReleaseLogItem,
	useSetVoluntaryReleaseMutation,
	useUndoVoluntaryReleaseMutation,
} from 'store/services/BlockScorecardService';
import { border, determineHeight, fontFamily } from 'utils';
import {
	getQueryError,
	handleApiResponse,
	ReleasePatternObject,
	useAppSelector,
	useGetReleasePatternsQuery,
} from 'store';
import { useFilters, useToast } from 'context';
import { ReleaseDialog } from 'pages/Block';

const colors = {
	YELLOW: '#ffa42e',
	BLUE: '#106ff4',
	GRAY: '#f2f2f2',
};

const legend = [
	{
		label: 'Automatic Release',
		color: colors.YELLOW,
	},
	{
		label: 'Voluntary Release',
		color: colors.BLUE,
	},
	{
		label: 'Allocated',
		color: colors.GRAY,
	},
];

const SORT_BY_OPTIONS = [
	{
		label: 'Allocation (low-high)',
		value: 'allocation_ascending',
	},
	{
		label: 'Allocation (high-low)',
		value: 'allocation_descending',
	},
	{
		label: 'Volume (low-high)',
		value: 'volume_ascending',
	},
	{
		label: 'Volume (high-low)',
		value: 'volume_descending',
	},
	{
		label: 'Alphabetical (a-z)',
		value: 'alphabetical_ascending',
	},
	{
		label: 'Alphabetical (z-a)',
		value: 'alphabetical_descending',
	},
];

const GROUP_BY_OPTIONS = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Quarter',
		value: 'quarter',
	},
	{
		label: 'Year',
		value: 'year',
	},
	{
		label: 'Block Name',
		value: 'block_name',
	},
];

const VIEW_BY_OPTIONS = [
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Block Name',
		value: 'block_name',
	},
];

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { allocated: number; automatic_release: number; voluntary_release: number; row_name: string };
}> {
	render() {
		const { x2, y2, datum } = this.props;
		const total_releases = (datum?.automatic_release ?? 0) + (datum?.voluntary_release ?? 0);
		const total_release_percent = Math.round((total_releases / (datum?.allocated ?? 1)) * 100);
		const automatic_release_percent = Math.round(((datum?.automatic_release ?? 0) / (datum?.allocated ?? 1)) * 100);
		const voluntary_release_percent = Math.round(((datum?.voluntary_release ?? 0) / (datum?.allocated ?? 1)) * 100);
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.row_name}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Allocated</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.allocated} hrs</p>
					</div>
					<div className='bg-blue-900 p-1 flex flex-col justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Total Releases</p>
						<div className='bg-blue-900 pt-1 flex justify-between'>
							<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{total_release_percent}%</p>
							<p className='text-right text-[0.25em] text-white opacity-80'>{total_releases} hrs</p>
						</div>
					</div>
					<div className='bg-blue-900 p-1 flex flex-col justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Automatic Releases</p>
						<div className='bg-blue-900 pt-1 flex justify-between'>
							<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{automatic_release_percent}%</p>
							<p className='text-right text-[0.25em] text-white opacity-80'>{datum?.automatic_release} hrs</p>
						</div>
					</div>
					<div className='bg-blue-900 p-1 flex flex-col justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Voluntary Releases</p>
						<div className='bg-blue-900 pt-1 flex justify-between'>
							<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{voluntary_release_percent}%</p>
							<p className='text-right text-[0.25em] text-white opacity-80'>{datum?.voluntary_release} hrs</p>
						</div>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: { allocated: number; automatic_release: number; voluntary_release: number; row_name: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' dx={5} style={{ fontFamily: fontFamily, fontSize: 5 }} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function ReleasePatterns() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);

	// Filters
	const {
		dateRange,
		daysOfWeek,
		blockNames,
		blockTimeslot,
		utilizationType,
		addOns,
		wheelsTurnoverTimeThreshold,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/release-patterns') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	// default: view by month
	const selectedViewBy = dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy : VIEW_BY_OPTIONS[0];
	// default: sort by time, descending (newest data -> oldest data)
	const selectedSortBy = dropDowns.sortBy.value !== 'undefined' ? dropDowns.sortBy : SORT_BY_OPTIONS[2];
	// default: group by year
	const selectedGroupBy = dropDowns.groupBy.value !== 'undefined' ? dropDowns.groupBy : GROUP_BY_OPTIONS[0];
	let groupByOptions = GROUP_BY_OPTIONS;

	const {
		data: releaseData,
		isFetching,
		error,
		refetch: refetchReleasePatternsQuery,
	} = useGetReleasePatternsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			sortby: selectedSortBy.value,
			groupby: selectedGroupBy.value,
			viewby: selectedViewBy.value,
			filters: {
				days_of_week: daysOfWeek?.applied,
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				block_names: blockNames?.applied,
				block_timeslot: blockTimeslot?.applied,
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	let exportData;
	if (releaseData?.data) {
		exportData = releaseData.data
			.map((group) => {
				const items = group.values.map((v) => {
					// we need any for exporting here
					// eslint-disable-next-line
					const row: any = {
						allocated: v.pattern.allocated,
						automatic_release: v.pattern.automatic_release,
						voluntary_release: v.pattern.voluntary_release,
					};
					const key = selectedViewBy.value.replace('_id', '');
					row[key] = v.label;
					if (selectedGroupBy.value !== 'none') {
						row[selectedGroupBy.value] = group.groupValue;
					}
					return row;
				});

				return items;
			})
			.flat();
	}

	const data = releaseData?.data ?? [];
	const maxHour = Math.max(
		...(data.map((res_obj, i) =>
			Math.max(...(res_obj.values.map((inner_obj, i) => inner_obj.pattern.allocated) ?? [1]))
		) ?? [1])
	);

	const [releaseBlocks] = useSetVoluntaryReleaseMutation();

	const submitRelease = async (blocks: BlockScheduleItem[]) => {
		const response = await releaseBlocks({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			data: blocks,
		});

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Blocks released successfully.',
					variant: 'success',
				});
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const [undoReleaseBlocks] = useUndoVoluntaryReleaseMutation();
	const submitUndoRelease = async (blocks: ReleaseLogItem[]) => {
		const response = await undoReleaseBlocks({ healthsystem_id: selectedSystem, data: blocks });

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block releases undone.',
					variant: 'success',
				});
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	// if viewby is changed, we need to limit the sortby options
	if (selectedViewBy.value === 'day_of_week') {
		groupByOptions = [
			{
				label: 'None',
				value: 'none',
			},
			...groupByOptions.filter(function (obj) {
				return !['day_of_week', 'service_line', 'block_name', 'none'].includes(obj.value);
			}),
			{
				label: 'Block Name',
				value: 'block_name',
			},
			{
				label: 'Service Line',
				value: 'service_line',
			},
		];
	} else if (selectedViewBy.value === 'service_line') {
		groupByOptions = [
			{
				label: 'None',
				value: 'none',
			},
			{
				label: 'Day of Week',
				value: 'day_of_week',
			},
			...groupByOptions.filter(function (obj) {
				return !['day_of_week', 'service_line', 'block_name', 'none'].includes(obj.value);
			}),

			{
				label: 'Block Name',
				value: 'block_name',
			},
		];
	} else if (selectedViewBy.value === 'block_name') {
		groupByOptions = [
			{
				label: 'None',
				value: 'none',
			},
			{
				label: 'Day of Week',
				value: 'day_of_week',
			},
			...groupByOptions.filter(function (obj) {
				return !['day_of_week', 'service_line', 'block_name', 'none'].includes(obj.value);
			}),

			{
				label: 'Service Line',
				value: 'service_line',
			},
		];
	}

	const isGrouped = selectedGroupBy?.value !== 'none';

	return (
		<div className='relative container mx-auto mb-48'>
			<div className='flex justify-between'>
				<PageHeading>Release Patterns</PageHeading>
			</div>

			<div className='relative'>
				{(isFetching || skipRequest || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}
				<div className='flex justify-end pb-4'>
					<ExportButton contents={exportData ?? []} />
				</div>
				<Panel
					title='Release Patterns'
					tooltipContent={`Use this visualization to determine how often a block owner releases their block, either voluntarily or automatically. In general, higher release rates or consistent patterns of automatic releases may be a sign to reduce overall block allocation.`}
					goToHelpID='commonDefinitions'
					headerContentRight={
						<>
							<div className='flex justify-end pb-3 items-center'>
								<Drawer
									metadata={metadata}
									filtersAreDirty={filtersAreDirty}
									trigger={
										<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
											<span className='material-symbol'>filter_alt</span>
											Filters
										</Button>
									}
									quickActions={[
										{
											icon: 'undo',
											onClick: resetFilters,
											tooltipText: 'Discard unapplied filter changes',
											disabled: !filtersAreDirty,
										},
										{
											icon: 'restart_alt',
											onClick: clearFilters,
											tooltipText: 'Reset filters to default',
											disabled: !metadata.saved_at,
										},
									]}
									actionButtons={[
										{
											onClick: applyFilters,
											children: 'Apply',
											disabled: !filtersAreDirty,
										},
										{
											// eslint-disable-next-line @typescript-eslint/no-empty-function
											onClick: () => {},
											children: 'Views',
											disabled: false,
										},
									]}
								>
									<FilterFields fields={['dateRange', 'blockNames', 'daysOfWeek', 'blockTimeslot']} dateRangeLimit={1} />
								</Drawer>
								<ReleaseDialog
									facility_id={selectedFacility}
									healthsystem_id={selectedSystem}
									selectedBlock={blockNames?.applied[0] ?? blockNames.all[0]}
									blockOptions={blockNames.all}
									selectableBlocks={true}
									requestRelease={(release) => {
										submitRelease(release);
										refetchReleasePatternsQuery();
									}}
									requestUndoRelease={(release) => {
										submitUndoRelease(release);
										refetchReleasePatternsQuery();
									}}
									filters={{
										days_of_week: daysOfWeek.applied,
										utilization_type: utilizationType.applied,
										add_ons: addOns.applied,
										wheels_turnover_threshold: wheelsTurnoverTimeThreshold.applied,
										start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
										end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
									}}
								>
									<Button sizeX='sm' sizeY='md' variant={'secondary'} className='mr-3'>
										Release
									</Button>
								</ReleaseDialog>
							</div>
						</>
					}
					isEmpty={releaseData?.data?.length === 0}
				>
					<div className='flex justify-between items-center'>
						<div className='flex gap-4'>
							<div className='w-36'>
								<Select
									label='View by'
									onChange={(selected) => {
										if (selected) {
											const optionInViewBy = VIEW_BY_OPTIONS.find((element) => element.value === selected.value);
											if (optionInViewBy) {
												let newGroupBy: { label: string; value: string } | undefined = undefined;
												if (optionInViewBy.value === 'day_of_week') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												} else if (optionInViewBy.value === 'service_line') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												} else if (optionInViewBy.value === 'block_name') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												}

												if (newGroupBy) {
													dropDowns.update({ ...dropDowns, groupBy: newGroupBy, viewBy: optionInViewBy });
													saveDropdown({ ...dropDowns, groupBy: newGroupBy, viewBy: optionInViewBy });
												} else {
													dropDowns.update({ ...dropDowns, viewBy: optionInViewBy });
													saveDropdown({ ...dropDowns, viewBy: optionInViewBy });
												}
											}
										}
									}}
									value={selectedViewBy}
									options={VIEW_BY_OPTIONS}
									defaultValue={selectedViewBy}
								/>
							</div>
							<div className='w-64'>
								<Select
									label='Sort by'
									options={SORT_BY_OPTIONS}
									value={selectedSortBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, sortBy: selected });
											saveDropdown({ ...dropDowns, sortBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Group by'
									options={groupByOptions}
									value={selectedGroupBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, groupBy: selected });
											saveDropdown({ ...dropDowns, groupBy: selected });
										}
									}}
								/>
							</div>
						</div>
						<div>
							<ChartLegend options={legend} />
						</div>
					</div>

					{isGrouped && data.length > 0 ? (
						<Accordion className='my-8' type='multiple' defaultValue={data.map((group) => `${group.groupValue}`)}>
							{data.map((group, i) => {
								return (
									<Accordion.Item key={i} value={`${group.groupValue}`} dark>
										<div className='h-full w-full'>
											<ReleaseBarChart values={group.values} maxHour={maxHour} />
										</div>
									</Accordion.Item>
								);
							})}
						</Accordion>
					) : (
						data.map((group, i) => {
							return (
								<div key={i} className='h-full w-full'>
									<ReleaseBarChart values={group.values} maxHour={maxHour} />
								</div>
							);
						})
					)}
				</Panel>
			</div>
		</div>
	);
}

export default ReleasePatterns;

interface ReleaseBarChartsProps {
	values: ReleasePatternObject['values'];
	maxHour: number;
}

function ReleaseBarChart({ values, maxHour }: ReleaseBarChartsProps) {
	const automaticReleases = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: `automaticRelease_${i}`,
			y: obj.pattern.automatic_release / 60,
			allocated: obj.pattern.allocated / 60,
			automatic_release: obj.pattern.automatic_release / 60,
			voluntary_release: obj.pattern.voluntary_release / 60,
		};
	});
	const voluntaryReleases = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: `voluntaryReleases_${i}`,
			y: obj.pattern.voluntary_release / 60,
			total_used: Math.round(
				((obj.pattern.voluntary_release + obj.pattern.automatic_release) / obj.pattern.allocated) * 100
			),
			total_hours: (obj.pattern.voluntary_release + obj.pattern.automatic_release) / 60,
			allocated: obj.pattern.allocated / 60,
			automatic_release: obj.pattern.automatic_release / 60,
			voluntary_release: obj.pattern.voluntary_release / 60,
		};
	});
	const allocated = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: `allocated_${i}`,
			y: (obj.pattern.allocated - (obj.pattern.voluntary_release + obj.pattern.automatic_release)) / 60,
			max: obj.pattern.allocated / 60,
			allocated: obj.pattern.allocated / 60,
			automatic_release: obj.pattern.automatic_release / 60,
			voluntary_release: obj.pattern.voluntary_release / 60,
		};
	});
	const avg = Math.round(voluntaryReleases.reduce((r, c) => r + c.x.toString().length, 0) / values.length);
	const regx = new RegExp('.{1,avg}(?:\\s|$)'.replace('avg', avg > 20 ? (avg - 2).toString() : '18'), 'g');

	return (
		<VictoryStack
			colorScale={[colors.YELLOW, colors.BLUE, colors.GRAY]}
			horizontal
			style={{ labels: { fontSize: 5, fontFamily: 'inter' } }}
			height={determineHeight(values.length, true)}
			domain={{ x: [0.5, values.length + 0.5], y: [0, (maxHour / 60) * 1.15] }}
			padding={{ top: 25, bottom: 10, right: 10, left: avg > 20 ? avg * 2.8 : 60 }}
		>
			<VictoryBar data={automaticReleases} barWidth={12.5} labelComponent={<CustomLabel />} labels={({ datum }) => ` `} />
			<VictoryBar
				data={voluntaryReleases}
				barWidth={12.5}
				labels={({ datum }) => `${datum.total_used}%`}
				style={{
					labels: { fontSize: 4.5, fontFamily: 'inter', fontWeight: 600, paddingTop: 100, fill: 'rgb(16, 111, 244)' },
				}}
				labelComponent={<CustomLabel />}
			/>
			<VictoryBar
				data={allocated}
				labels={({ datum }) => ``}
				barWidth={12.5}
				cornerRadius={3}
				labelComponent={<CustomLabel />}
			/>

			<VictoryAxis
				standalone
				tickValues={values.map((obj) => obj.label)}
				tickFormat={values.map((d) => d.label.match(regx))}
				tickLabelComponent={<VictoryLabel style={{ fontSize: 5, fontFamily: 'inter' }} />}
				axisComponent={<LineSegment style={{ stroke: border }} />}
			/>
			<VictoryAxis
				dependentAxis
				tickLabelComponent={<VictoryLabel style={{ fontSize: 5, fontFamily: 'inter' }} />}
				axisComponent={<LineSegment style={{ stroke: border }} />}
				tickFormat={(x) => `${x} hrs`}
				padding={{ top: 0 }}
				gridComponent={<LineSegment style={{ stroke: border, padding: { bottom: 100 } }} />}
				orientation='top'
			/>
		</VictoryStack>
	);
}

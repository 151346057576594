import { apiWithCore, ShortOption } from 'store';
import { ApiEndpoints, Facility } from 'models';
import { StaffingGridItem } from 'pages/Anesthesia/components';
import { IntraopNoteKeyMap, ScheduleKeyMapItem, ScheduleMetadataMap } from 'pages';
import { StringLocale } from 'yup/lib/locale';

export type EventType =
	| 'none'
	| 'fcots_delay'
	| 'case'
	| 'case_addon'
	| 'turnover'
	| 'turnover_warning'
	| 'case_schedule'
	| 'wheels_in'
	| 'wheels_out';

export interface TimelineEvent {
	color: string;
	details: {
		room: string;
		surgeon: string;
		time: string;
	};
	title: string;
	event_type: EventType;
	surgeon: string | undefined;
	procedure: string | undefined;
	data: [
		{
			x: string;
			y0: number;
			y: number;
			metadata: string;
			surgeon: string | undefined;
			distance_from_last_event?: number | undefined;
		}
	];
}

interface RoomTurnoverResponse {
	events: TimelineEvent[];
	prime_time_end: string;
	prime_time_start: string;
	turnover_target: number;
}

/**
 * Not a huge fan of the name of this interface, but needed something to capture data from the API where a metric may
 * or may not have a valid value for "this" period or the "prior" period.
 * */
interface PotentialComparisonMetric {
	name: string;
	current: number | null;
	previous: number | null;
}

interface CoreDashboardResponse {
	overview_date_start: Date;
	overview_date_end: Date;
	current_month: string;
	previous_month: string | null;
	volume: {
		current: number;
		previous: number | null;
		average: number;
		top_surgeons: PotentialComparisonMetric[];
	};
	addons: {
		current: number | null;
		previous: number | null;
		target: number;
		weekdays: PotentialComparisonMetric[];
	};
	fcots: {
		current: number;
		previous: number | null;
		target: number;
		top_rooms: PotentialComparisonMetric[];
	};
	turnover: {
		current: number;
		previous: number | null;
		target: number;
		on_target: PotentialComparisonMetric;
	};
}

export interface TimelyStartsData {
	groupValue?: string | number | null;
	values: {
		x: string | number;
		y: number;
		on_time_starts?: number;
		total_first_cases?: number;
	}[];
}

export interface TurnoverData {
	groupValue?: string | number | null;
	wheels_tot_values: {
		x: string | number;
		y: number;
		number_of_wheels_turnovers: number;
	}[];
	proc_tot_values: {
		x: string | number;
		y: number;
		number_of_proc_turnovers: number;
	}[];
}

export interface FlipRoomItem {
	surgeon_name: string;
	total_case_vol: number;
	number_of_flips: number;
	flip_rate: number;
	avg_case_duration_when_flipped: number;
	avg_case_vol_on_flip_days: number;
	avg_procedure_time_when_flipped: number;
}

interface TimelyStartsResponse {
	data: TimelyStartsData[] | undefined;
}

interface RoomsRunningResponse {
	data: {
		y: string;
		x: number[];
	}[];
}

export interface Shift {
	[key: string]: string | number;
}

export interface RrItem {
	[key: string]: { x: number; y: number }[];
}

export interface RollupItem {
	after_hours_fte: number;
	model_type: string;
	shift_fte: number;
	total_fte: number;
	details: Shift[];
	column_sum: Shift;
}

export interface AnesthesiaCoverageResponse {
	data: {
		rr: {
			y: string;
			x: number[];
		}[];
		crna_rr: RrItem;
		crna_coverage: RrItem;
		mda_rr: RrItem;
		mda_coverage: RrItem;
		crna_fte_coverage_rollup: RollupItem[];
		mda_fte_coverage_rollup: RollupItem[];
		mda_staffing_grid: StaffingGridItem[];
		crna_staffing_grid: StaffingGridItem[];
	};
}

export interface NurseCoverageResponse {
	data: {
		concurrency_output_minutes: {
			[key: number]: number;
			overall: number;
			model_type: string;
			details: { [key: number]: number; day_of_week: string; day_of_week_int: number; overall: number }[];
		}[];
		rr: {
			y: string;
			x: number[];
		}[];
		total_staff_rr: RrItem;
		rn_coverage: RrItem;
		surg_tech_coverage: RrItem;
		var_staff_coverage: RrItem;
		indirect_staff_coverage: RrItem;
		total_staff_coverage: RrItem;
		rn_fte_coverage_rollup: RollupItem[];
		surg_tech_fte_coverage_rollup: RollupItem[];
		var_staff_fte_coverage_rollup: RollupItem[];
		indirect_staff_fte_coverage_rollup: RollupItem[];
		total_staff_fte_coverage_rollup: RollupItem[];
		rn_staffing_grid: StaffingGridItem[];
		surg_tech_staffing_grid: StaffingGridItem[];
		var_staff_staffing_grid: StaffingGridItem[];
		indirect_staff_staffing_grid: StaffingGridItem[];
		total_staff_staffing_grid: StaffingGridItem[];
	};
}

// export interface DelayDetails {
// 	surgeon_name: string;
// 	avg_delay: number;
// 	num_delays: number;
// 	total_delay_mins: number;
// }

export interface DelayDetailItem {
	date: string;
	name: string;
	service_line: string;
	entity_id: number;
	num_delays: number;
	fullDate: string;
}

export interface DelayDetailsResponse {
	delay_data: DelayDetailItem[];
	group_by: string;
	latest_date: string;
	start_date: string;
	delay_metadata: {
		[key: number | string]: TopProcedureDelayItem[];
	};
}

export interface TopProcedureDelayItem {
	avg_delay: number;
	num_delays: number;
	name: string; //generalizing to any view by (surgeon, service line, etc.);
	surgeon_name: string;
	delay_reason: string;
	total_delay_mins: number;
}

export interface TurnoverResponse {
	data: TurnoverData[] | undefined;
}

export interface FlipRoomsResponse {
	data: FlipRoomItem[];
}

interface RoomTurnOverRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	filters: {
		rooms: ShortOption[];
		surgeons?: string[];
		date: string;
		fcots_grace_minutes: number;
		procedures: string[];
		wheels_turnover_threshold: number;
		proc_turnover_threshold: number;
	};
	useCached?: boolean;
	show_time_details?: boolean;
}

// this corresponds to the hover state of a calendar heatmap item
type ScheduledDayItem = {
	fill_rate: number;
	scheduled_cases: number;
	scheduled_minutes: number;
	date: Date;
	day_of_week: string;
	week_of_month: string;
	facility: Facility;
};

type ScheduleWithAddOnProxyItem = {
	label: Date;
	scheduled_minutes: number;
	estimated_add_on_minutes: number;
	available_minutes: number;
};

// this corresponds to a row in the fill rate details table
type ScheduleDetailItem = {
	id: number;
	date: Date;
	scheduled_primetime_minutes: number;
	scheduled_turnover_minutes: number;
	scheduled_non_primetime_minutes: number;
	available_minutes: number;
	number_of_rooms: number;
	fill_rate: number;
	number_of_scheduled_cases: number;
	day_of_week: string;
};

export interface FillRateResponse {
	fill_rate_schedule: ScheduledDayItem[];
	fill_rate_with_add_on_proxy: ScheduleWithAddOnProxyItem[];
	schedule_details: ScheduleDetailItem[];
}

export interface ScheduledCaseItem {
	id: number;
	room: string;
	surgeon: string;
	room_id: number;
	surgeon_id: number;
	start: string;
	end: string;
	match_score: number;
	record_type?: string;
	procedure_description: string;
	schedule_key?: string;
	case_order: number;
	create_to_start_hours: number;
	offShade?: boolean;
}

export interface SetScheduledCaseMetadata {
	checked: string[];
	comments: string;
}

export interface HuddleHeatmapItem {
	date: Date;
	count: number;
	case_minutes: number;
	facility_utilization: number;
}

/**
 * Provides endpoints for retrieving and setting block settings
 */
export const coreApi = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		getRoomTurnover: build.query<RoomTurnoverResponse, RoomTurnOverRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_TIMELINE_DATA,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getCoreDashboard: build.query<CoreDashboardResponse, { facility_id: number | null }>({
			query: ({ facility_id }) => ({
				url: ApiEndpoints.GET_CORE_DASHBOARD,
				params: {
					facility_id: facility_id !== null ? facility_id : undefined,
				},
			}),
			providesTags: ['Core'],
		}),
		getTimelyStarts: build.query<
			TimelyStartsResponse,
			{
				facility_id: number | null;
				healthsystem_id: number | null;
				groupby: string;
				sortby: string;
				viewby: string;
				filters: {
					surgeons: ShortOption[];
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					start_date: string;
					end_date: string;
					procedures: string[];
					fcots_grace_minutes: number;
					add_ons: string;
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_TIMELY_STARTS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getDelayDetails: build.query<
			DelayDetailsResponse,
			{
				facility_id: number | null;
				healthsystem_id: number | null;
				groupby: string;
				filters: {
					surgeons: ShortOption[];
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					start_date: string;
					end_date: string;
					procedures: string[];
					fcots_grace_minutes: number;
					add_ons: string;
				};
				viewby: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_DELAY_DETAILS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getRoomsRunning: build.query<
			RoomsRunningResponse,
			{
				facility_id: number | null;
				filters?: {
					days_of_week: string[];
					rooms: ShortOption[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					surgeons: ShortOption[];
					add_ons: string;
					start_date: string;
					end_date: string;
					percentile: number;
					heatmap_method: string;
					turnover_concurrency: 'Included' | 'Excluded' | 'Only Turnover';
					wheels_turnover_threshold: number;
				};
				filter_id?: number;
				type?: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_ROOMS_RUNNING,
				method: 'POST',
				body,
				params: { type: body.type !== undefined ? body.type : undefined },
			}),
			providesTags: ['Core'],
		}),
		getTurnover: build.query<
			TurnoverResponse,
			{
				facility_id: number | null;
				healthsystem_id: number | null;
				groupby: string;
				sortby: string;
				viewby: string;
				filters: {
					surgeons: ShortOption[];
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					procedures: string[];
					start_date: string;
					end_date: string;
					wheels_turnover_threshold: number;
					primetime: string;
					add_ons: string;
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_TURNOVER,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getFlipRooms: build.query<
			FlipRoomsResponse,
			{
				facility_id: number | null;
				filters: {
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					procedures: string[];
					start_date: string;
					end_date: string;
					primetime: string;
					add_ons: string;
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_FLIP_ROOMS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getFillRate: build.query<
			FillRateResponse,
			{
				facility_id: number | null;
				filters: {
					days_of_week: string[];
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_FILL_RATE,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getScheduledCases: build.query<
			{ scheduled_cases: ScheduledCaseItem[] },
			{
				surgery_date: string;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULED_CASES,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getIntraopScheduledCasesMeshed: build.query<
			{ meshed_records: ScheduledCaseItem[] },
			{
				surgery_date: string;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_INTRAOP_SCHEDULED_CASES_MESH,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		setScheduledCaseElements: build.mutation<
			{ success: boolean },
			{
				surgery_date: string;
				room_id: number;
				surgeon_id: number;
				case_order: number;
				metadata: SetScheduledCaseMetadata;
				procedure_description: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_SCHEDULED_CASES_METADATA,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ScheduleMetadata'],
		}),
		getScheduledCaseElements: build.query<
			{ metadata: SetScheduledCaseMetadata },
			{
				surgery_date: string;
				room_id: number;
				surgeon_id: number;
				case_order: number;
				procedure_description: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULED_CASES_METADATA,
				method: 'POST',
				body,
			}),
		}),
		addIntraopScheduleParentLink: build.mutation<
			{ success: boolean },
			{
				surgery_date: string;
				room_id: number;
				surgeon_id: number;
				case_order: number;
				metadata: any;
				procedure_description: string;
				schedule_key: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.ADD_INTRAOP_SCHEDULED_PARENT_LINK,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ScheduleMap'],
		}),
		removeIntraopScheduleParentLink: build.mutation<
			{ success: boolean },
			{
				surgery_date: string;
				room_id: number;
				surgeon_id: number;
				case_order: number;
				metadata: any;
				procedure_description: string;
				schedule_key: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.REMOVE_INTRAOP_SCHEDULED_PARENT_LINK,
				method: 'POST',
				body,
			}),
			// invalidatesTags: ['ScheduleMetadata'],
		}),
		getIntraopLinkStatus: build.query<
			{ linked: string | boolean; schedule_key: string },
			{
				surgery_date: string;
				room_id: number;
				surgeon_id: number;
				case_order: number;
				procedure_description: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_INTRAOP_LINK_STATUS,
				method: 'POST',
				body,
			}),
			// invalidatesTags: ['ScheduleMetadata'],
		}),
		getScheduleLinkStatus: build.query<
			{ linked: string | boolean },
			{
				schedule_key: string;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULE_LINK_STATUS,
				method: 'POST',
				body,
			}),
			// invalidatesTags: ['ScheduleMetadata'],
		}),
		getIntraopNotes: build.query<
			{ delay_reason: string },
			{
				intraop_id: number;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_INTRAOP_NOTES,
				method: 'POST',
				body,
			}),
			// invalidatesTags: ['ScheduleMetadata'],
		}),
		setIntraopNotes: build.mutation<
			{ success: boolean },
			{
				delay_reason: string;
				intraop_id: number;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_INTRAOP_NOTES,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['IntraopMap'],
		}),
		getIntraopScheduleMap: build.query<
			{ records: ScheduleKeyMapItem },
			{
				surgery_date: string;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULE_INTROAP_MAP,
				method: 'POST',
				body,
			}),
			providesTags: ['ScheduleMap'],
		}),
		getIntraopNoteMap: build.query<
			{ records: IntraopNoteKeyMap },
			{
				surgery_date: string;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_INTRAOP_NOTE_MAP,
				method: 'POST',
				body,
			}),
			providesTags: ['IntraopMap'],
		}),
		getScheduleMetadata: build.query<
			{ records: ScheduleMetadataMap },
			{
				surgery_date: string;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULE_METADATA,
				method: 'POST',
				body,
			}),
			providesTags: ['ScheduleMetadata'],
		}),

		getDailyHuddleHeatmap: build.query<
			{
				records: HuddleHeatmapItem[];
			},
			{
				start_date: string;
				end_date: string;
				facility_id: number | null;
				filters: {
					rooms: ShortOption[];
					surgeons: ShortOption[];
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_DAILY_HUDDLE_HEATMAP,
				method: 'POST',
				body,
			}),
			providesTags: ['DailyHuddle'],
		}),
	}),
});

export const {
	useGetRoomTurnoverQuery,
	useGetCoreDashboardQuery,
	useGetTimelyStartsQuery,
	useGetDelayDetailsQuery,
	useGetTurnoverQuery,
	useGetRoomsRunningQuery,
	useGetFlipRoomsQuery,
	useGetFillRateQuery,
	useGetScheduledCasesQuery,
	useSetScheduledCaseElementsMutation,
	useGetScheduledCaseElementsQuery,
	useGetIntraopScheduledCasesMeshedQuery,
	useAddIntraopScheduleParentLinkMutation,
	useRemoveIntraopScheduleParentLinkMutation,
	useGetIntraopLinkStatusQuery,
	useGetScheduleLinkStatusQuery,
	useGetIntraopNotesQuery,
	useSetIntraopNotesMutation,
	useGetIntraopScheduleMapQuery,
	useGetIntraopNoteMapQuery,
	useGetScheduleMetadataQuery,
	useGetDailyHuddleHeatmapQuery,
} = coreApi;

export const {
	endpoints: {
		getRoomTurnover,
		getCoreDashboard,
		getTimelyStarts,
		getDelayDetails,
		getTurnover,
		getRoomsRunning,
		getFlipRooms,
		getFillRate,
		getScheduledCases,
		setScheduledCaseElements,
		getScheduledCaseElements,
		getIntraopScheduledCasesMeshed,
		addIntraopScheduleParentLink,
		removeIntraopScheduleParentLink,
		getIntraopLinkStatus,
		getScheduleLinkStatus,
		setIntraopNotes,
		getIntraopNotes,
		getIntraopScheduleMap,
		getIntraopNoteMap,
		getScheduleMetadata,
		getDailyHuddleHeatmap,
	},
} = coreApi;

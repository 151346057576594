import classNames from 'classnames';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button, WorkingIndicator } from 'components';

export interface FileUploadProps {
	dropFileFn: (arg: File[]) => void;
	isLoading: boolean;
	disabled: boolean;
	mode: 'intraop' | 'schedule' | 'delay_details';
}

export function FileDropzone({ dropFileFn, isLoading, disabled, mode }: FileUploadProps) {
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			dropFileFn(acceptedFiles);
		},
		[dropFileFn]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: disabled });

	let upload_style = 'border-blue-500 bg-blue-50';

	switch (mode) {
		case 'intraop':
			upload_style = 'border-blue-500 bg-blue-50';
			break;
		case 'schedule':
			upload_style = 'border-purple-500 bg-purple-50';
			break;
		default:
			upload_style = 'border-blue-500 bg-blue-50';
			break;
	}

	return (
		<div
			className={classNames(
				'h-80 p-10 pb-12 pt-8 border-dashed border-4 text-center transition-colors',
				(isDragActive || isLoading) && 'animate-fastPulse',
				isLoading ? 'border-green-500 bg-green-50' : upload_style,
				disabled && 'opacity-50 cursor-not-allowed'
			)}
			{...getRootProps()}
		>
			<input {...getInputProps()} />

			<div className='flex flex-col h-full'>
				{!isLoading ? (
					<>
						<span className='material-symbol-lg p-2'>upload</span>
						<h3 className='font-bold text-h5 p-2'>Drag and drop your files here</h3>
						<p className='text-p3 text-gray-500 p-2'>File formats supported: XLS, CSV, XLSX</p>
						<p className='font-bold text-h6'>or</p>
						<div className='m-auto p-2'>
							<Button sizeX='md' sizeY='md' disabled={disabled}>
								Browse Files
							</Button>
						</div>
						<p className='text-p2 text-gray-500 p-2'>Maximum file size: 200MB</p>
					</>
				) : (
					<WorkingIndicator size='lg' className='m-auto' />
				)}
			</div>
		</div>
	);
}

export default FileDropzone;

import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	Button,
	ButtonStack,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Tooltip,
	WorkingIndicator,
} from 'components';
import { getDowNameFromNumber, truncateLabel } from 'utils';
import { SurgeonTableData, useSurgeonScorecardQuery } from 'store/services/SurgeonScorecardService';
import { useAppSelector, useGetSystemsQuery, useSystem } from 'store';
import { useFilters, useToast } from 'context';
import Logo from 'assets/images/branding/logo_jpg.jpg';

const SelectedDateRangeDisplay = (props: { startDate: string; endDate: string }) => {
	return (
		<div className='flex text-center bg-blue-500 items-center justify-center h-10 rounded-md mr-2'>
			<span className='text-b3 font-semibold px-6 text-white'>{`${props.startDate} – ${props.endDate}`}</span>
		</div>
	);
};

const SurgeonTable = (props: {
	title: string;
	data: SurgeonTableData | undefined;
	startDate: string;
	endDate: string;
	lockTable: boolean;
	minLimit?: Date;
}) => {
	function truncate(str: string) {
		return str.length > 35 ? str.substring(0, 35) + '...' : str;
	}

	return (
		<div className={props.lockTable ? 'animate-pulse cursor-not-allowed' : ''}>
			<DataTable
				title={props.title}
				tooltipContent={`Use this page to track monthly operational performance metrics for an individual surgeon. Expand the rows for additional detail.`}
				goToHelpID='commonDefinitions'
				headerContentCenterAlignment='left'
				disablePagination
				disableRowCounter
				columns={[
					{
						accessorKey: 'metric',
						header: 'Metric',
						cell: ({ row }) => <span className='font-semibold'>{row.original.metric}</span>,
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'surgeon',
						header: 'Surgeon',
						cell: ({ row }) => <span className='font-semibold'>{row.original.surgeon}</span>,
						enableGlobalFilter: false,
						enableSorting: false,
					},
				]}
				data={props.data?.overview ?? []}
				rowSubview={(row) => (
					<div className='flex'>
						{row.original.metric === 'Case Volume' && (
							<div className='bg-white p-4 pt-5 rounded-md h-fit'>
								<table>
									<colgroup span={2}></colgroup>
									<thead>
										<tr>
											<th
												colSpan={2}
												scope='colgroup'
												className='pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
											>
												Case Volume by DOW
											</th>
										</tr>
									</thead>
									<tbody>
										{props.data?.case_volume.dow.map((dowData, i) => {
											return (
												<tr key={i}>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
														{getDowNameFromNumber(dowData.day_of_week)}
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>{dowData.volume}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{row.original.metric === 'Add-On Rate' && (
							<div className='bg-white p-4 pt-5 rounded-md h-fit'>
								<table>
									<colgroup span={2}></colgroup>
									<thead>
										<tr>
											<th
												colSpan={2}
												scope='colgroup'
												className='pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
											>
												Add-On Volume by DOW
											</th>
										</tr>
									</thead>
									<tbody>
										{props.data?.addon_rate.dow.map((addonData, i) => {
											return (
												<tr key={i}>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
														{getDowNameFromNumber(addonData.day_of_week)}
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>{addonData.volume}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						<table className='border-separate border-spacing-x-10 w-fit pt-4 h-fit'>
							{(row.original.metric === 'Case Volume' || row.original.metric === 'Add-On Rate') && (
								<>
									<thead>
										<tr>
											<th colSpan={2} scope='col' className='text-left text-bluegray-900'></th>
											<th colSpan={4} scope='col' className='text-center text-white bg-gray-200 rounded-md text-[0.9em] uppercase'>
												Averages
											</th>
										</tr>
										<tr>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>
													Top{' '}
													{row.original.metric === 'Case Volume'
														? props.data?.case_volume.top5.length
														: props.data?.addon_rate.top5.length}{' '}
													Cases by Vol
												</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Vol.</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Pat-Out</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Proc-Start</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Start to Proc-Stop</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Stop to Pat-Out</span>
											</th>
										</tr>
									</thead>
								</>
							)}

							<tbody>
								{row.original.metric === 'Case Volume' &&
									props.data?.case_volume.top5.map((caseData, i) => {
										return (
											<tr key={i}>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
													{truncate(caseData.name)}
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>{caseData.volume}</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.avg)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.wheels_in_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.procedure_time_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.wheels_out_avg / 60)} min
												</td>
											</tr>
										);
									})}

								{row.original.metric === 'FCOTS Rate' && (
									<React.Fragment>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Number of On Time Starts
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.fcots_rate.number_of_first_cases_on_time}
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Number of First Cases
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.fcots_rate.number_of_first_cases}
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Average FCOTS Rate Delay
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.fcots_rate.avg_fcots_delay !== 0 ? `${props.data?.fcots_rate.avg_fcots_delay} min` : '0 min'}
											</td>
										</tr>
									</React.Fragment>
								)}

								{row.original.metric === 'Average Wheels Out to Wheels In Time' && (
									<>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Number of Turnovers
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_wheels_tot.number_of_turnovers}
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Turnovers On-Target
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_wheels_tot.number_turnovers_on_target}%
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Flip Rate
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_wheels_tot.flip_rate}%
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Avg. Case Vol. when Flipped
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_wheels_tot.avg_case_vol !== 0
													? `${props.data?.average_wheels_tot.avg_case_vol} Cases`
													: '0 Cases'}
											</td>
										</tr>
										{/* <tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Close to Cut Turnover
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>
												{props.data?.average_tot.close_cut_turnover} min
											</td>
										</tr> */}
									</>
								)}
								{row.original.metric === 'Average Proc Out to Proc In Time' && (
									<>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Number of Turnovers
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_surgeon_tot.number_of_turnovers}
											</td>
										</tr>
										<tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Turnovers On-Target
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>
												{props.data?.average_surgeon_tot.number_turnovers_on_target}%
											</td>
										</tr>
										{/* <tr>
											<td className='p-0 py-4 text-p3 border-b border-bluegray-100 uppercase font-semibold text-bluegray-900'>
												Close to Cut Turnover
											</td>
											<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>
												{props.data?.average_tot.close_cut_turnover} min
											</td>
										</tr> */}
									</>
								)}
								{row.original.metric === 'Add-On Rate' &&
									props.data?.addon_rate.top5.map((addonData, i) => {
										return (
											<tr key={i}>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
													{truncate(addonData.name)}
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>{addonData.volume}</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(addonData.avg)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(addonData.wheels_in_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(addonData.procedure_time_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(addonData.wheels_out_avg / 60)} min
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				)}
			/>
		</div>
	);
};

const SurgeonTables = (props: {
	title: string;
	data: SurgeonTableData[] | undefined;
	startDate: string;
	endDate: string;
	lockTable: boolean;
	minLimit?: Date;
}) => {
	function truncate(str: string) {
		return str.length > 19 ? str.substring(0, 19) + '...' : str;
	}

	return (
		<div className={props.lockTable ? 'animate-pulse cursor-not-allowed' : ''}>
			<DataTable
				title={props.title}
				tooltipContent={
					'Use this page to track monthly operational performance metrics for an individual surgeon. Expand the rows for additional detail.'
				}
				disablePagination
				disableRowCounter
				columns={[
					{
						accessorKey: 'surgeon_name',
						header: 'Surgeon',
						enableGlobalFilter: true,
						cell: ({ row }) => <span className='font-semibold block w-52'>{row.original.surgeon_name}</span>,
					},
					{
						accessorKey: 'cases',
						header: 'Cases',
						cell: ({ row }) => <span>{row.original.overview[0].surgeon.split(' ')[0]}</span>,
						enableGlobalFilter: false,
						sortingFn: (rowA, rowB) => {
							const a = parseInt(rowA.original.overview[0].surgeon.split(' ')[0]);
							const b = parseInt(rowB.original.overview[0].surgeon.split(' ')[0]);
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'fcots_rate',
						header: 'FCOTS Rate',
						cell: ({ row }) => <span>{row.original.overview[1].surgeon}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = parseInt(rowA.original.overview[1].surgeon.replace('%', ''));
							const b = parseInt(rowB.original.overview[1].surgeon.replace('%', ''));
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'primetime_tot',
						header: 'Avg. Primetime TOT',
						cell: ({ row }) => <span>{row.original.overview[2].surgeon}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							let a = parseInt(rowA.original.overview[2].surgeon.split(' ')[0]);
							let b = parseInt(rowB.original.overview[2].surgeon.split(' ')[0]);

							if (Number.isNaN(a)) {
								a = 0;
							}

							if (Number.isNaN(b)) {
								b = 0;
							}

							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'addon_rate',
						header: 'Add-On Rate',
						cell: ({ row }) => <span>{row.original.overview[4].surgeon}</span>,
						enableGlobalFilter: false,
						sortingFn: (rowA, rowB) => {
							const a = parseInt(rowA.original.overview[4].surgeon);
							const b = parseInt(rowB.original.overview[4].surgeon);
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'first_on_time',
						header: 'On Time Starts',
						cell: ({ row }) => <span>{row.original.fcots_rate.number_of_first_cases_on_time}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.fcots_rate.number_of_first_cases_on_time;
							const b = rowB.original.fcots_rate.number_of_first_cases_on_time;
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'first_cases',
						header: 'First Cases',
						cell: ({ row }) => <span>{row.original.fcots_rate.number_of_first_cases}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.fcots_rate.number_of_first_cases;
							const b = rowB.original.fcots_rate.number_of_first_cases;
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'avg_fcots',
						header: 'Avg. FCOTS Delay',
						cell: ({ row }) => <span>{row.original.fcots_rate.avg_fcots_delay} Min</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.fcots_rate.avg_fcots_delay;
							const b = rowB.original.fcots_rate.avg_fcots_delay;
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'num_wheels_turnovers',
						header: 'Wheels Out Next Wheels In Turnovers',
						cell: ({ row }) => <span>{row.original.average_wheels_tot.number_of_turnovers}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.average_wheels_tot.number_of_turnovers;
							const b = rowB.original.average_wheels_tot.number_of_turnovers;
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'num_surgeon_turnovers',
						header: 'Turnovers',
						cell: ({ row }) => <span>{row.original.average_surgeon_tot.number_of_turnovers}</span>,
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.average_surgeon_tot.number_of_turnovers;
							const b = rowB.original.average_surgeon_tot.number_of_turnovers;
							return b > a ? 1 : -1;
						},
					},
				]}
				data={props.data ?? []}
				rowSubview={(row) => (
					<div className='flex flex-col'>
						{row.original.case_volume.top5.length > 0 && (
							<div className='flex'>
								<div className='bg-white p-4 pt-5 rounded-md h-fit'>
									<table>
										<colgroup span={2}></colgroup>
										<thead>
											<tr>
												<th
													colSpan={2}
													scope='colgroup'
													className='whitespace-nowrap pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
												>
													Case Volume by DOW
												</th>
											</tr>
										</thead>
										<tbody>
											{row.original.case_volume.dow.map((dowData, i) => {
												return (
													<tr key={i}>
														<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
															{getDowNameFromNumber(dowData.day_of_week)}
														</td>
														<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>{dowData.volume}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>

								<table className='border-separate border-spacing-x-10 w-fit pt-4 h-fit'>
									<tr>
										<th colSpan={2} scope='col' className='text-left text-bluegray-900'></th>
										<th colSpan={4} scope='col' className='text-center text-white bg-gray-200 rounded-md text-[0.9em] uppercase'>
											Averages
										</th>
									</tr>
									<tr>
										<th className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>
												Top {row.original.case_volume.top5.length} Cases by Vol
											</span>
										</th>
										<th scope='col' className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Vol.</span>
										</th>
										<th scope='col' className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Pat-Out</span>
										</th>
										<th scope='col' className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Proc-Start</span>
										</th>
										<th scope='col' className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Start to Proc-Stop</span>
										</th>
										<th scope='col' className='pb-4 text-left text-bluegray-900'>
											<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Stop to Pat-Out</span>
										</th>
									</tr>

									{row.original.case_volume.top5.map((caseData, i) => {
										return (
											<tr key={i}>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
													<Tooltip content={caseData.name}>{truncate(caseData.name)}</Tooltip>
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>{caseData.volume}</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.avg)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.wheels_in_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.procedure_time_avg / 60)} min
												</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
													{Math.round(caseData.wheels_out_avg / 60)} min
												</td>
											</tr>
										);
									})}
								</table>
							</div>
						)}
						<div className='h-0.5 my-8 bg-gray-200 rounded-lg w-100'></div>
						{row.original.addon_rate.top5.length > 0 && (
							<div className='flex'>
								<div className='bg-white p-4 pt-5 rounded-md h-fit'>
									<table>
										<colgroup span={2}></colgroup>
										<thead>
											<tr>
												<th
													colSpan={2}
													scope='colgroup'
													className='whitespace-nowrap pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
												>
													ADD-ON VOLUME BY DOW
												</th>
											</tr>
										</thead>
										<tbody>
											{row.original.addon_rate.dow.map((dowData, i) => {
												return (
													<tr key={i}>
														<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
															{getDowNameFromNumber(dowData.day_of_week)}
														</td>
														<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>{dowData.volume}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>

								<table className='border-separate border-spacing-x-10 w-fit pt-4 h-fit'>
									<>
										<thead>
											<tr>
												<th colSpan={2} scope='col' className='text-left text-bluegray-900'></th>
												<th
													colSpan={4}
													scope='col'
													className='text-center text-white bg-gray-200 rounded-md text-[0.9em] uppercase'
												>
													Averages
												</th>
											</tr>
											<tr>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>
														Top {row.original.addon_rate.top5.length} Add-On Cases by Vol
													</span>
												</th>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Vol.</span>
												</th>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Pat-Out</span>
												</th>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Pat-In to Proc-Start</span>
												</th>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Start to Proc-Stop</span>
												</th>
												<th className='pb-4 text-left text-bluegray-900'>
													<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Proc-Stop to Pat-Out</span>
												</th>
											</tr>
										</thead>
									</>

									<tbody>
										{row.original.addon_rate.top5.map((caseData, i) => {
											return (
												<tr key={i}>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
														<Tooltip content={caseData.name}>{truncate(caseData.name)}</Tooltip>
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center'>{caseData.volume}</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
														{Math.round(caseData.avg)} min
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
														{Math.round(caseData.wheels_in_avg / 60)} min
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
														{Math.round(caseData.procedure_time_avg / 60)} min
													</td>
													<td className='p-0 py-4 text-p2 border-b border-bluegray-100 text-center whitespace-nowrap'>
														{Math.round(caseData.procedure_time_avg / 60)} min
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
			/>
		</div>
	);
};

export function SurgeonScorecard() {
	// Filters
	const {
		surgeons,
		dateRange,
		wheelsTurnoverTimeThreshold,
		fcotsGraceMinutes,
		procedures,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		endDate,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/surgeon-scorecard') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const minLimit = new Date(`${endDate.getFullYear() - 5}-Jan`);
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data, isFetching, error, isLoading } = useSurgeonScorecardQuery(
		{
			facility_id: selectedFacility,
			surgeon_id: surgeons?.applied.map((s) => s.id) ?? [],
			start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
			end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
			fcots_grace: fcotsGraceMinutes?.applied,
			tot_threshold: wheelsTurnoverTimeThreshold?.applied,
			procedures: procedures?.applied.map((procedure) => procedure.name),
		},
		{
			skip: surgeons?.applied?.map((s) => s.id).length < 1 || skipRequest || filtersAreFetching,
		}
	);

	const { data: userData } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const system = userData?.healthsystems.find((h) => h.id === selectedSystem);
	const { data: allFacilities } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const current_facility = allFacilities?.facilities?.find((f) => f.id === selectedFacility);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	const exportData =
		// eslint-disable-next-line
		data?.surgeonData
			.filter((v) => v.surgeon_name)
			.map((v) => ({
				surgeon_name: v.surgeon_name,
				cases: v.overview[0].surgeon.split(' ')[0],
				fcots_rate: v.overview[1].surgeon,
				avg_primetime_tot: v.overview[2].surgeon,
				addon_rate: v.overview[4].surgeon,
				num_on_time_starts: v.fcots_rate.number_of_first_cases_on_time,
				num_first_cases: v.fcots_rate.number_of_first_cases,
				avg_fcots_delay_mins: v.fcots_rate.avg_fcots_delay,
				num_turnovers: v.average_wheels_tot.number_of_turnovers,
			})) ?? [];

	return (
		<>
			<div>
				<PageHeading>
					{surgeons?.applied.length === 0
						? ''
						: surgeons?.applied.length === 1
						? surgeons?.applied[0].name
						: 'Surgeon Scorecard'}
				</PageHeading>
				<div className='flex justify-end pb-3 z-50'>
					{(isLoading || skipRequest || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}
					{!isFetching ? (
						<SelectedDateRangeDisplay
							startDate={format(dateRange?.applied.startDate, 'M/d/yyyy')}
							endDate={format(dateRange?.applied.endDate, 'M/d/yyyy')}
						/>
					) : (
						<div className='p-2.5'>
							<WorkingIndicator size='lg' />
						</div>
					)}
					<Drawer
						metadata={metadata}
						filtersAreDirty={filtersAreDirty}
						trigger={
							<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
								<span className='material-symbol'>filter_alt</span>
								Filters
							</Button>
						}
						quickActions={[
							{
								icon: 'undo',
								onClick: resetFilters,
								tooltipText: 'Discard unapplied filter changes',
								disabled: !filtersAreDirty,
							},
							{
								icon: 'restart_alt',
								onClick: clearFilters,
								tooltipText: 'Reset filters to default',
								disabled: !metadata.saved_at,
							},
						]}
						actionButtons={[
							{
								onClick: applyFilters,
								children: 'Apply',
								disabled: !filtersAreDirty,
							},
							{
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick: () => {},
								children: 'Views',
								disabled: false,
							},
						]}
					>
						<FilterFields
							fields={['surgeons', 'wheelsTurnoverTimeThreshold', 'fcotsGraceMinutes', 'dateRange', 'procedures']}
						/>
					</Drawer>
					<div className='z-10'>
						{surgeons.applied.length !== 0 && !isLoading && exportData.length > 0 ? (
							<ButtonStack>
								<Button sizeX='md' sizeY='md' variant='primary'>
									<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData}>
										Export CSV
									</ExportButton>
								</Button>

								<Button sizeX='md' sizeY='md' variant='primary'>
									<PDFDownloadLink
										document={
											<MyDocument
												surgeonData={data?.surgeonData}
												startDate={format(dateRange?.applied.startDate, 'M/d/yyyy')}
												endDate={format(dateRange?.applied.endDate, 'M/d/yyyy')}
												healthsystem={system?.name}
												facility={current_facility?.name}
											/>
										}
										fileName={'surgeon_scorecard'}
									>
										<p className='text-white font-regular text-left'>Export PDF</p>
									</PDFDownloadLink>
								</Button>
							</ButtonStack>
						) : (
							<span className='opacity-50 cursor-not-allowed'>
								<ExportButton contents={[]} />
							</span>
						)}
					</div>
				</div>
				{surgeons.applied.length !== 0 && data?.surgeonData[0] && (
					<React.Fragment>
						{/* <div className='grid grid-cols-3 gap-x-3 justify-center align-middle'>
						<BlockPanel title='Block' className='opacity-50 cursor-not-allowed' />
						<BlockPanel title='Volume by Month' />
						<BlockPanel title='Saved Views with Surgeon' className='opacity-50 cursor-not-allowed' />
						<PatternInfoBlock items={pattern_info} />
						<SurgeonVolumeBarGraph items={volume_graph_data} />
						<SavedViewsBlock items={saved_views} />
					</div> */}
						{!isLoading && surgeons.applied.length === 1 && data?.surgeonData[0] ? (
							<SurgeonTable
								title={isFetching ? '' : `Surgeon Overview`}
								startDate={format(dateRange?.applied.startDate, 'M/d/yyyy')}
								endDate={format(dateRange?.applied.endDate, 'M/d/yyyy')}
								data={data?.surgeonData[0]}
								lockTable={isFetching}
								minLimit={minLimit}
							/>
						) : (
							<SurgeonTables
								title={isFetching ? '' : `Surgeon Overview`}
								startDate={format(dateRange?.applied.startDate, 'M/d/yyyy')}
								endDate={format(dateRange?.applied.endDate, 'M/d/yyyy')}
								data={data?.surgeonData.filter((element) => element.surgeon_name !== '')}
								lockTable={isFetching}
								minLimit={minLimit}
							/>
						)}
					</React.Fragment>
				)}

				{surgeons.applied.length === 0 && (
					<React.Fragment>
						<img className='h-16 w-16 block m-auto mt-32' src={Logo} alt='Merlin' />
						<p className='text-p1 text-center mt-3'>Please select a surgeon using the filters button.</p>
					</React.Fragment>
				)}
			</div>
		</>
	);
}

// Create styles for PDF
const document_styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	section_1: {
		margin: 20,
		padding: 20,
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: 16,
	},
	section_1_sub: {
		margin: 20,
		padding: 20,
		flexDirection: 'column',
		alignItems: 'flex-end',
		fontSize: 16,
	},
	section_2: {
		margin: 20,
		padding: 20,
		paddingTop: 0,
		paddingBottom: 0,
		marginTop: 0,
		marginBottom: 10,
		flexDirection: 'column',
		alignItems: 'flex-start',
		fontSize: 18,
	},
	section_2_sub: {
		margin: 10,
		padding: 10,
		flexDirection: 'column',
		alignItems: 'flex-start',
		fontSize: 18,
	},
	section_3: {
		margin: 20,
		padding: 20,
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: 18,
	},
	header_wrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	header_wrapper_2: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: 20,
		marginRight: 20,
	},
	header_wrapper_3: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '75%',
		marginLeft: 20,
		marginRight: 20,
	},
	duration_style: {
		margin: 0,
		paddingLeft: 1.5,
		fontSize: 11,
		opacity: 0.9,
		width: 75,
		textAlign: 'right',
		whiteSpace: 'nowrap',
	},
	logo: {
		height: 50,
		width: 50,
	},
});

// Create Document Component for PDF
const MyDocument = (props: {
	surgeonData: SurgeonTableData[] | undefined;
	startDate: string;
	endDate: string;
	healthsystem: string | undefined;
	facility: string | undefined;
}) => (
	<Document>
		{props.surgeonData
			?.filter((record) => record.surgeon_name)
			.map((payload, ind) => (
				<Page size='A4' style={document_styles.page} key={ind}>
					<View style={document_styles.header_wrapper}>
						<View style={document_styles.section_1}>
							<Image src={Logo} style={document_styles.logo} />
							<Text style={{ margin: 0, padding: 0, opacity: 0.5 }}>Merlin</Text>
						</View>
						<View style={document_styles.section_1_sub}>
							<Text style={{ margin: 0, padding: 0, opacity: 0.5 }}>Individual Surgeon Scorecard</Text>
							<Text style={{ margin: 0, padding: 0, opacity: 0.5, fontSize: 12 }}>
								{props.startDate} - {props.endDate}
							</Text>
						</View>
					</View>
					<View style={document_styles.header_wrapper}>
						<View style={document_styles.section_2}>
							<Text style={{ margin: 0, padding: 0, fontSize: 26, opacity: 0.6 }}>{payload.surgeon_name}</Text>
							<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 12, opacity: 0.5 }}>
								{props.healthsystem} - {props.facility}
							</Text>
						</View>
					</View>
					<View style={document_styles.header_wrapper_2}>
						{payload.overview.map((item, index) => (
							<View style={document_styles.section_2_sub} key={index}>
								<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 20, opacity: 0.7, textAlign: 'center' }}>
									{item.surgeon.includes('Cases') ? `${item.surgeon.split(' ')[0]}` : item.surgeon}
								</Text>
								<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.5 }}>
									{item.metric === 'Case Volume' ? 'Volume' : item.metric}
								</Text>
							</View>
						))}
					</View>
					<View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
						<View style={document_styles.header_wrapper_3}>
							{/* Table header */}
							{(payload.case_volume.top5 ? payload.case_volume.top5.length > 0 : false) && (
								<View
									style={{
										borderBottom: '1px solid #00000f',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: 10,
										marginRight: 15,
										marginLeft: 20,
										paddingBottom: 2,
										paddingTop: 10,
									}}
								>
									<Text style={{ width: 250, paddingLeft: 1.5, fontSize: 10, opacity: 0.9, textAlign: 'left' }}>
										Top {payload.case_volume.top5 ? payload.case_volume.top5.length : ''} Procedures by Volume
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.9, width: 90, textAlign: 'right' }}>
										VOL
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.9, width: 90, textAlign: 'right' }}>
										Avg. DUR
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.9, width: 90, textAlign: 'right' }}>
										Avg. Start
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.9, width: 90, textAlign: 'right' }}>
										Avg. End
									</Text>
								</View>
							)}
							{/* Table body */}
							{payload.case_volume.top5.map((item, index) => (
								<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }} key={index}>
									<Text style={{ width: 250, marginLeft: 20, paddingLeft: 1.5, fontSize: 10, opacity: 0.8, textAlign: 'left' }}>
										{truncateLabel(item.name, 30)}
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.8, width: 50, textAlign: 'center' }}>
										{item.volume}
									</Text>
									<Text
										style={{
											margin: 0,
											paddingLeft: 1.5,
											fontSize: 11,
											opacity: 0.8,
											width: 75,
											textAlign: 'center',
										}}
									>
										{`${item.avg}m`}
									</Text>
									<Text
										style={{
											margin: 0,
											paddingLeft: 1.5,
											fontSize: 11,
											opacity: 0.8,
											width: 75,
											textAlign: 'center',
										}}
									>
										{`${Math.round(item.wheels_in_avg / 60)}m`}
									</Text>
									<Text
										style={{
											margin: 0,
											paddingLeft: 1.5,
											fontSize: 11,
											opacity: 0.8,
											width: 75,
											textAlign: 'center',
										}}
									>
										{`${Math.round(item.wheels_out_avg / 60)}m`}
									</Text>
								</View>
							))}
						</View>

						{/* Case details dow */}
						<View
							style={{
								marginTop: 1,
								flexDirection: 'column',
								justifyContent: 'flex-start',
								marginBottom: 10,
								marginRight: 33,
							}}
						>
							{(payload.case_volume.top5 ? payload.case_volume.top5.length > 0 : false) && (
								<View
									style={{
										borderBottom: '1px solid #00000f',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: 10,
										paddingBottom: 2,
										paddingTop: 10,
									}}
								>
									<Text style={{ width: 105, fontSize: 10, opacity: 0.7, textAlign: 'left' }}>Case Vol. by DOW</Text>
								</View>
							)}
							{payload.case_volume.dow.map((item, index) => (
								<View
									style={{ width: 105, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}
									key={index}
								>
									<Text style={{ width: 50, fontSize: 10, opacity: 0.7, textAlign: 'left' }}>
										{getDowNameFromNumber(item.day_of_week)}
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.7, width: 50, textAlign: 'right' }}>
										{item.volume}
									</Text>
								</View>
							))}
						</View>
					</View>
					{(payload.case_volume.top5 ? payload.case_volume.top5.length > 0 : false) && (
						<>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									marginLeft: 40,
									marginRight: 30,
									marginBottom: 20,
									marginTop: 20,
									borderTop: '1px solid black',
									borderBottom: '1px solid black',
								}}
							>
								<View style={{ width: 170, marginTop: 10, flexDirection: 'column', justifyContent: 'flex-start' }}>
									<Text style={{ width: 160, fontSize: 10, textAlign: 'left', marginBottom: 10 }}>
										First Case On-Time Starts Details
									</Text>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Number of On Time Starts</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.fcots_rate.number_of_first_cases_on_time}
										</Text>
									</View>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Number of First Cases</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.fcots_rate.number_of_first_cases}
										</Text>
									</View>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Average FCOTS Rate Delay</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.fcots_rate.avg_fcots_delay !== 0 ? `${payload.fcots_rate.avg_fcots_delay} min` : '0 min'}
										</Text>
									</View>
								</View>
								<View style={{ width: 200, marginTop: 10, flexDirection: 'column', justifyContent: 'flex-end' }}>
									<Text style={{ fontSize: 10, textAlign: 'right', marginBottom: 10 }}>Primetime Turnover Details</Text>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Number of Turnovers</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.average_wheels_tot.number_of_turnovers}
										</Text>
									</View>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Turnovers On-Target</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.average_wheels_tot.number_turnovers_on_target}%
										</Text>
									</View>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Flip Rate</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.average_wheels_tot.flip_rate}%
										</Text>
									</View>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20, opacity: 0.6 }}>
										<Text style={{ width: 130, fontSize: 10, textAlign: 'left' }}>Avg. Case Vol. when Flipped</Text>
										<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>
											{payload.average_wheels_tot.avg_case_vol !== 0 ? `${payload.average_wheels_tot.avg_case_vol}` : '0'}
										</Text>
									</View>
								</View>
							</View>
						</>
					)}
					<View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
						<View style={document_styles.header_wrapper_3}>
							{/* Table header */}
							{(payload.addon_rate.top5 ? payload.addon_rate.top5.length > 0 : false) && (
								<View
									style={{
										borderBottom: '1px solid rgb(75, 75, 75)',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: 10,
										marginRight: 15,
										marginLeft: 20,
										paddingBottom: 2,
										paddingTop: 10,
									}}
								>
									<Text style={{ width: 250, paddingLeft: 1.5, fontSize: 11, opacity: 0.9, textAlign: 'left' }}>
										Top {payload.addon_rate.top5 ? payload.addon_rate.top5.length : ''} Add-On Procedures by Volume
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 11, opacity: 0.9, width: 50 }}>Volume</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 11, opacity: 0.9, width: 75, textAlign: 'right' }}>
										Avg. Duration
									</Text>
								</View>
							)}
							{/* Table body */}
							{payload.addon_rate.top5.map((item, index) => (
								<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }} key={index}>
									<Text style={{ width: 250, marginLeft: 20, paddingLeft: 1.5, fontSize: 10, opacity: 0.8, textAlign: 'left' }}>
										{truncateLabel(item.name, 40)}
									</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, opacity: 0.8, width: 50, textAlign: 'center' }}>
										{item.volume}
									</Text>
									<Text
										style={{
											margin: 0,
											paddingLeft: 1.5,
											fontSize: 10,
											opacity: 0.8,
											width: 75,
											textAlign: 'center',
										}}
									>
										{item.avg} mins
									</Text>
								</View>
							))}
						</View>

						{/* addon details dow */}
						<View
							style={{
								marginTop: 1,
								opacity: 0.7,
								flexDirection: 'column',
								justifyContent: 'flex-start',
								marginBottom: 10,
								marginRight: 33,
							}}
						>
							{(payload.addon_rate.top5 ? payload.addon_rate.top5.length > 0 : false) && (
								<View
									style={{
										borderBottom: '1px solid rgb(75, 75, 75)',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: 10,
										paddingBottom: 2,
										paddingTop: 10,
									}}
								>
									<Text style={{ width: 105, fontSize: 10, textAlign: 'left' }}>Add-On Vol. by DOW</Text>
								</View>
							)}
							{payload.addon_rate.dow.map((item, index) => (
								<View
									style={{ width: 105, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}
									key={index}
								>
									<Text style={{ width: 50, fontSize: 10, textAlign: 'left' }}>{getDowNameFromNumber(item.day_of_week)}</Text>
									<Text style={{ margin: 0, paddingLeft: 1.5, fontSize: 10, width: 50, textAlign: 'right' }}>{item.volume}</Text>
								</View>
							))}
						</View>
					</View>
				</Page>
			))}
	</Document>
);

export default SurgeonScorecard;

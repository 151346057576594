import { VictoryBoxPlot } from 'victory-box-plot';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { useEffect, useState } from 'react';
import { VictoryZoomContainer } from 'victory-zoom-container';
import * as Tabs from '@radix-ui/react-tabs';
import { ColumnSort, Row, SortingState } from '@tanstack/table-core';
import { format } from 'date-fns';

import {
	Button,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
	ToggleGroup,
	Tooltip,
} from 'components';
import {
	BoxWhiskerItem,
	BoxWhiskerItemPlain,
	ProviderPatternsResponse,
	useAppSelector,
	useGetProviderPatternsQuery,
} from 'store';
import {
	border,
	dayEndMinute,
	dayStartMinute,
	defaultZoomState,
	fontFamily,
	hourAxisTicks,
	minutesToHours,
	truncateLabel,
} from 'utils';
import { useFilters } from 'context';
import { tabContentClassname, tabTriggerClassname } from 'pages/Block';

const iconStyle = 'material-symbols-outlined text-[1.5em] text-bluegray-500';
export const iconStyleStart = 'material-symbols-outlined text-[1.5em] text-bluegray-500';
const iconStyleEnd = 'material-symbols-outlined text-[1.5em] text-bluegray-500';
const textStyleStart = 'text-[0.8em] text-bluegray-500 pl-0.5 font-semibold';
const textStyleEnd = 'text-[0.8em] text-bluegray-500 pl-0.5 font-semibold';
const textStyle = 'text-[0.8em] text-bluegray-500 pl-0.5 font-semibold';

export function ProviderPatterns() {
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const {
		dateRange,
		daysOfWeek,
		anesthesiologists,
		anesthetists,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	const [tab, setTab] = useState('anesthesiologists');

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/provider-patterns') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const { data, isFetching } = useGetProviderPatternsQuery(
		{
			healthsystem_id: selectedSystem,
			filters: {
				days_of_week: daysOfWeek?.applied,
				anesthesiologists: anesthesiologists?.applied,
				anesthetists: anesthetists?.applied,
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	return (
		<div>
			<PageHeading>Provider Work Patterns</PageHeading>
			{(isFetching || !data || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}
			{(!isFetching || data) && (
				<div className='p-4 border border-blue-500 rounded-sm'>
					<Tabs.Root defaultValue='anesthesiologists' orientation='vertical'>
						<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
							<Tabs.Trigger
								value='anesthesiologists'
								className={tabTriggerClassname}
								onClick={(e) => setTab('anesthesiologists')}
							>
								Anesthesiologists
							</Tabs.Trigger>
							<Tabs.Trigger value='anesthetists' className={tabTriggerClassname} onClick={(e) => setTab('anesthetists')}>
								Anesthetists
							</Tabs.Trigger>
							<div className='flex justify-end pb-3 overflow-auto w-full'>
								<div className='flex text-center bg-blue-500 items-center justify-center gap-0 rounded-md mr-3'>
									<span className='text-b3 font-semibold px-6 text-white'>
										{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
									</span>
								</div>
								<Drawer
									metadata={metadata}
									filtersAreDirty={filtersAreDirty}
									trigger={
										<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
											<span className='material-symbol'>filter_alt</span>
											Filters
										</Button>
									}
									quickActions={[
										{
											icon: 'undo',
											onClick: resetFilters,
											tooltipText: 'Discard unapplied filter changes',
											disabled: !filtersAreDirty,
										},
										{
											icon: 'restart_alt',
											onClick: clearFilters,
											tooltipText: 'Reset filters to default',
											disabled: !metadata.saved_at,
										},
									]}
									actionButtons={[
										{
											onClick: applyFilters,
											children: 'Apply',
											disabled: !filtersAreDirty,
										},
										{
											// eslint-disable-next-line @typescript-eslint/no-empty-function
											onClick: () => {},
											children: 'Views',
											disabled: false,
										},
									]}
								>
									<FilterFields
										fields={['dateRange', 'daysOfWeek', tab === 'anesthesiologists' ? 'anesthesiologists' : 'anesthetists']}
										dateRangeLimit={1}
									/>
								</Drawer>
								<div>
									<Button sizeX='md' sizeY='md' variant='primary' disabled>
										<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={['']}>
											Export CSV
										</ExportButton>
									</Button>
								</div>
							</div>
						</Tabs.List>
						<Tabs.Content value='anesthesiologists' className={tabContentClassname}>
							<DataTable
								noHover
								expansionHeaders={['Start & End Distribution']}
								headerIconHeaderMap={{
									'Average Start': {
										element: (
											<Tooltip content='Average Start'>
												<span className={iconStyleStart}>start</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Average End': {
										element: (
											<Tooltip content='Average End'>
												<span className={`${iconStyleEnd}`}>last_page</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Earliest Start': {
										element: (
											<Tooltip content='Earliest Start'>
												<span className={iconStyleStart}>clock_loader_10</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Longest Shift': {
										element: (
											<Tooltip content='Longest Shift'>
												<span className={iconStyleEnd}>clock_loader_90</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Days Worked': {
										element: (
											<Tooltip content='Days Worked'>
												<span className={iconStyle}>calendar_month</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Total Cases': {
										element: (
											<Tooltip content='Total Cases'>
												<span className={iconStyle}>cases</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Average Shift Length': {
										element: (
											<Tooltip content='Average Shift Length'>
												<span className={iconStyle}>avg_pace</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Shortest Shift': {
										element: (
											<Tooltip content='Shortest Shift'>
												<span className={iconStyle}>clock_loader_10</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Excluded Cases': {
										element: (
											<Tooltip content='Excluded Cases'>
												<span className={iconStyle}>do_not_disturb_on</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
								}}
								minimalStyle
								columns={[
									{
										header: 'Provider Name',
										accessorKey: 'x',
										enableColumnFilter: true,
										cell: ({ row }) => {
											return (
												<div className='w-40'>
													<p>{row.original.x}</p>
												</div>
											);
										},
									},

									{
										header: 'Average Start',
										accessorKey: 'avg_shift_time',
										cell: ({ row }) => {
											return <p className={textStyleStart}>{minutesToTime(row.original.avg_shift_time)}</p>;
										},
									},

									{
										header: 'Average End',
										accessorKey: 'q1',
										cell: ({ row }) => {
											return <p className={textStyleEnd}>{minutesToTime(row.original.end_box.avg_shift_time)}</p>;
										},
										sortingFn: (rowA, rowB) => {
											return rowA.original.end_box.avg_shift_time > rowB.original.end_box.avg_shift_time ? 1 : -1;
										},
									},
									{
										header: 'Shortest Shift',
										accessorKey: 'shortest',
										cell: ({ row }) => {
											return <p className={textStyleStart}>{minutesToHours(row.original.shortest)}</p>;
										},
									},

									{
										header: 'Average Shift Length',
										accessorKey: 'average',
										cell: ({ row }) => {
											return <p className={textStyle}>{minutesToHours(row.original.average)}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Longest Shift',
										accessorKey: 'longest',
										cell: ({ row }) => {
											return <p className={textStyleEnd}>{minutesToHours(row.original.end_box.longest)}</p>;
										},
										sortingFn: (rowA, rowB) => {
											return rowA.original.end_box.max > rowB.original.end_box.max ? 1 : -1;
										},
									},

									{
										header: 'Days Worked',
										accessorKey: 'days',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.days}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Total Cases',
										accessorKey: 'cases',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.cases}</p>;
										},
									},
									{
										header: 'Excluded Cases',
										accessorKey: 'omitted_cases',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.omitted_cases}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Start & End Distribution',
										accessorKey: '',
										cell: ({ row }) => {
											return (
												<ProviderBoxPlotTime
													index={row.index}
													data={[row.original, row.original.end_box]}
													distinctAnes={10}
													row={row}
												/>
											);
										},
										enableColumnFilter: false,
									},
								]}
								data={data?.anesthesiologists ?? []}
								title={''}
							/>
						</Tabs.Content>
						<Tabs.Content value='anesthetists' className={tabContentClassname}>
							<DataTable
								noHover
								expansionHeaders={['Start & End Distribution']}
								headerIconHeaderMap={{
									'Average Start': {
										element: (
											<Tooltip content='Average Start'>
												<span className={iconStyleStart}>start</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Average End': {
										element: (
											<Tooltip content='Average End'>
												<span className={`${iconStyleEnd}`}>last_page</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Earliest Start': {
										element: (
											<Tooltip content='Earliest Start'>
												<span className={iconStyleStart}>start</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Longest Shift': {
										element: (
											<Tooltip content='Longest Shift'>
												<span className={iconStyleEnd}>clock_loader_90</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Days Worked': {
										element: (
											<Tooltip content='Days Worked'>
												<span className={iconStyle}>calendar_month</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Total Cases': {
										element: (
											<Tooltip content='Total Cases'>
												<span className={iconStyle}>cases</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Average Shift Length': {
										element: (
											<Tooltip content='Average Shift Length'>
												<span className={iconStyle}>avg_time</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Shortest Shift': {
										element: (
											<Tooltip content='Shortest Shift'>
												<span className={iconStyle}>clock_loader_10</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
									'Excluded Cases': {
										element: (
											<Tooltip content='Excluded Cases'>
												<span className={iconStyle}>do_not_disturb_on</span>
											</Tooltip>
										),
										arrow_style: 'text-[1.5em] text-bluegray-500',
									},
								}}
								minimalStyle
								columns={[
									{
										header: 'Provider Name',
										accessorKey: 'x',
										enableColumnFilter: true,
										cell: ({ row }) => {
											return (
												<div className='w-40'>
													<p>{row.original.x}</p>
												</div>
											);
										},
									},

									{
										header: 'Average Start',
										accessorKey: 'avg_shift_time',
										cell: ({ row }) => {
											return <p className={textStyleStart}>{minutesToTime(row.original.avg_shift_time)}</p>;
										},
									},

									{
										header: 'Average End',
										accessorKey: 'q1',
										cell: ({ row }) => {
											return <p className={textStyleEnd}>{minutesToTime(row.original.end_box.avg_shift_time)}</p>;
										},
										sortingFn: (rowA, rowB) => {
											return rowA.original.end_box.avg_shift_time > rowB.original.end_box.avg_shift_time ? 1 : -1;
										},
									},
									{
										header: 'Shortest Shift',
										accessorKey: 'shortest',
										cell: ({ row }) => {
											return <p className={textStyleStart}>{minutesToHours(row.original.shortest)}</p>;
										},
									},

									{
										header: 'Average Shift Length',
										accessorKey: 'average',
										cell: ({ row }) => {
											return <p className={textStyle}>{minutesToHours(row.original.average)}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Longest Shift',
										accessorKey: 'longest',
										cell: ({ row }) => {
											return <p className={textStyleEnd}>{minutesToHours(row.original.end_box.longest)}</p>;
										},
										sortingFn: (rowA, rowB) => {
											return rowA.original.end_box.max > rowB.original.end_box.max ? 1 : -1;
										},
									},

									{
										header: 'Days Worked',
										accessorKey: 'days',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.days}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Total Cases',
										accessorKey: 'cases',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.cases}</p>;
										},
									},
									{
										header: 'Excluded Cases',
										accessorKey: 'omitted_cases',
										cell: ({ row }) => {
											return <p className={textStyle}>{row.original.omitted_cases}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},

									{
										header: 'Start & End Distribution',
										accessorKey: '',
										cell: ({ row }) => {
											return (
												<ProviderBoxPlotTime
													index={row.index}
													data={[row.original, row.original.end_box]}
													distinctAnes={10}
													row={row}
												/>
											);
										},
										enableColumnFilter: false,
									},
								]}
								data={data?.anesthetists ?? []}
								title={''}
							/>
						</Tabs.Content>
					</Tabs.Root>
				</div>
			)}
		</div>
	);
}

// this contains any because multiple components use this and they can have very different structures
interface ProviderBoxPlotProps {
	distinctAnes: number;
	data: any | undefined;
	index: number;
	row: any;
}

export function ProviderBoxPlotTime({ distinctAnes, data, index, row }: ProviderBoxPlotProps) {
	const [zoomState, setZoomState] = useState({ y: defaultZoomState });
	const zoomStateRange = Math.abs(zoomState.y[1] - zoomState.y[0]);

	let roomAxisLabelBaseFontSize: number;
	let timeAxisLabelBaseFontSize: number;

	if (zoomStateRange < 720) {
		roomAxisLabelBaseFontSize = 4;
		timeAxisLabelBaseFontSize = 7;
	} else if (zoomStateRange < 1200) {
		roomAxisLabelBaseFontSize = 4;
		timeAxisLabelBaseFontSize = 8;
	} else {
		roomAxisLabelBaseFontSize = 4;
		timeAxisLabelBaseFontSize = 5;
	}

	const roomAxisStyles = {
		axis: { stroke: '#00000000' },
		tickLabels: { fontFamily: fontFamily, fontSize: roomAxisLabelBaseFontSize, padding: 5 },
	};

	const timeAxisStyles = {
		axis: { stroke: border },
		// grid: { stroke: border },
		ticks: { stroke: border, size: 5 },
		tickLabels: {
			fontFamily: fontFamily,
			fontSize: timeAxisLabelBaseFontSize,
			padding: 5,
			fill: 'hsl(212 40% 69%)',
		},
	};
	return (
		<div className='py-2' key={index}>
			{' '}
			<VictoryChart
				domainPadding={10}
				horizontal
				height={40}
				width={550}
				domain={{ y: [dayStartMinute, dayEndMinute] }}
				padding={{ top: 20, bottom: -20, right: 5, left: 10 }}
				containerComponent={
					<VictoryZoomContainer
						onZoomDomainChange={(domain) => {
							// domain is { x: DomainTuple, y: DomainTuple }
							// not quite sure how to convert that to numbers?
							const [start, end] = domain.y;
							if (typeof start === 'number' && typeof end === 'number') {
								const newStart = 60 * Math.ceil(start / 60.0);
								const newEnd = 60 * Math.ceil(end / 60.0);
								// TODO: it would probably be much more performant to do a debounce here, after like 50-100ms of delay
								// but didn't have time to do that when first implementing
								setZoomState({
									y: [newStart, newEnd],
								});
							}
						}}
						allowPan={true}
						allowZoom={false}
						zoomDomain={zoomState}
						zoomDimension='y'
					/>
				}
			>
				<VictoryAxis style={roomAxisStyles} tickValues={['', '']} tickFormat={(tick) => truncateLabel(tick, 13)} />
				<VictoryBoxPlot
					boxWidth={10}
					data={[data ? data[0] : []]}
					style={{
						q1: { fill: 'hsl(215 90% 83%)' }, // styles the lower part of the box
						q3: { fill: 'hsl(215 90% 83%)' }, // styles the upper part of the box
						min: { stroke: 'hsl(0 0% 80% / 1)' }, // styles the lower whisker
						max: { stroke: 'hsl(0 0% 80% / 1)' }, // styles the upper whisker
						median: { stroke: 'white' }, // styles the median line
					}}
				/>
				<VictoryBoxPlot
					boxWidth={10}
					data={[data ? data[1] : []]}
					style={{
						q1: { fill: 'hsl(211 36% 59%)' }, // styles the lower part of the box
						q3: { fill: 'hsl(211 36% 59%)' }, // styles the upper part of the box
						min: { stroke: 'hsl(0 0% 80% / 1)' }, // styles the lower whisker
						max: { stroke: 'hsl(0 0% 80% / 1)' }, // styles the upper whisker
						median: { stroke: 'white' }, // styles the median line
					}}
				/>
				<VictoryAxis
					label='Time'
					orientation='top'
					style={timeAxisStyles}
					dependentAxis
					tickValues={hourAxisTicks}
					tickFormat={(m: number) => `${m / 60}:00`}
				/>
			</VictoryChart>
		</div>
	);
}

function minutesToTime(minutes: number): string {
	const hours = Math.floor(minutes / 60);
	const mins = minutes % 60;
	return `${hours}:${mins.toString().padStart(2, '0')}`;
}

export default ProviderPatterns;

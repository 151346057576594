import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import image from 'assets/images/empty.svg';
import { Routes } from 'models';
import { Tooltip } from 'components';

import type { HTMLAttributes } from 'react';
import type { TooltipProps } from 'components';

interface PanelProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
	/** The title to display in this panel's header. */
	title?: React.ReactNode;

	/** The unstyled title to display in this panel's header. We built this because the title bolds text by default */
	unStyledTitle?: React.ReactNode;

	/** The tooltip content to attach to an information icon next to this panel's title. */
	tooltipContent?: TooltipProps['content'];

	/** The id which will scroll to in help center when click information icon next to this panel's title. */
	goToHelpID?: string;

	/** Additional content or controls to display in the center area of this panel's header. */
	headerContentCenter?: React.ReactNode;

	/**
	 * The desired positioning of content in the center area of this panel's header. When set to `center`, the content
	 * will stay aligned with the center of this panel; when set to `left`, the content will align itself next to this
	 * panel's title.
	 */
	headerContentCenterAlignment?: 'center' | 'left';

	/** Additional content or controls to display to the rightmost area of this panel's header. */
	headerContentRight?: React.ReactNode;

	/** State to display empty graphic. */
	isEmpty?: boolean;

	/** Additional content for empty graphic. */
	emptyMessage?: string;

	/** Remove header from panel */
	noHeader?: boolean;

	defaultTitleClass?: string;
}

export function Panel({
	children,
	className,
	headerContentCenter,
	isEmpty,
	noHeader,
	emptyMessage = 'This graph has no data to display based on the provided filters.',
	headerContentCenterAlignment = 'center',
	headerContentRight,
	title,
	unStyledTitle,
	goToHelpID = 'default',
	defaultTitleClass = 'font-semibold max-w-[300px] text-ellipsis',
	tooltipContent,
	...props
}: PanelProps) {
	const navigate = useNavigate();
	return (
		<div className={classNames('bg-white border border-blue-500 rounded-sm text-p1 font-primary', className)} {...props}>
			{!noHeader && (
				<div
					className={classNames(
						'grid gap-8 items-center py-4 px-8 border-b border-gray-200',
						{
							'grid-cols-[1fr_auto_1fr]': headerContentCenterAlignment === 'center',
							'grid-cols-[auto_1fr_auto]': headerContentCenterAlignment === 'left',
						},
						className
					)}
				>
					<div className='flex items-center gap-1'>
						{unStyledTitle ? (
							<div className='max-w-[300px] text-ellipsis'>{unStyledTitle}</div>
						) : (
							<div className={defaultTitleClass}>{title}</div>
						)}

						{tooltipContent && (
							<Tooltip content={tooltipContent}>
								<div
									className='material-symbol-sm text-blue-500'
									onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${goToHelpID}`)}
								>
									info
								</div>
							</Tooltip>
						)}
					</div>

					<div>{headerContentCenter}</div>

					<div className='justify-self-end'>{headerContentRight}</div>
				</div>
			)}

			<div className='py-4 px-8'>
				{isEmpty ? (
					<div className='flex flex-col items-center'>
						<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600'>
							<img className='w-44' alt='An empty clipboard' src={image} />
							{emptyMessage}
						</div>
					</div>
				) : (
					children
				)}
			</div>
		</div>
	);
}

export default Panel;

import { ApiEndpoints } from 'models/api';
import { apiWithCore } from 'store/api';
import { ShortOption } from 'store';

export interface FacilityUtilizationDetailsItem {
	available_minutes: number;
	distinct_dates: number;
	in_primetime_minutes: number;
	primetime_turnover_minutes: number;
	total_daily_primetime_window_minutes: number;
	non_primetime_minutes: number;
	utilization_percentage: number;
	utilization_numerator: number;
	total_case_volume: number;
	month: number;
	year: number;
	facility_id: number;
}

export interface FacilityUtilizationOverviewItem {
	available_minutes: number;
	primetime_minutes: number;
	day_of_week?: number;
	week_of_year?: string;
	month?: number;
	quarter?: number;
	year?: number;
	facility_id: number;
	utilization_percentage: number;
	turnover_minutes: number;
}

export interface FacilityUtilizationOverviewResponse {
	facility_utilization_overview_data: FacilityUtilizationOverviewItem[];
	group_by: string;
}

export interface FacilityUtilizationDetailsResponse {
	data: FacilityUtilizationDetailsItem[];
	group_by: string;
}

/**
 * Provides endpoints for data values in database
 */
export const facilityUtilizationService = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		getFacilityUtilizationDetails: build.query<
			FacilityUtilizationDetailsResponse,
			{
				facility_id: number | null;
				wheels_turnover_threshold: number | null;
				groupby: string;
				filters: {
					surgeons: ShortOption[];
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					procedures: string[];
					start_date: string;
					end_date: string;
					utilization_type: string;
				};
				useCached?: boolean;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_FACILITY_UTILIZATION_DETAILS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getFacilityUtilizationOverview: build.query<
			FacilityUtilizationOverviewResponse,
			{
				facility_id: number | null;
				wheels_turnover_threshold: number | null;
				groupby: string;
				filters: {
					surgeons: ShortOption[];
					days_of_week: string[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					rooms: ShortOption[];
					procedures: string[];
					start_date: string;
					end_date: string;
					utilization_type: string;
				};
				useCached?: boolean;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_FACILITY_UTILIZATION_OVERVIEW,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
	}),
});

export const { useGetFacilityUtilizationDetailsQuery, useGetFacilityUtilizationOverviewQuery } =
	facilityUtilizationService;

export const {
	endpoints: { getFacilityUtilizationDetails, getFacilityUtilizationOverview },
} = facilityUtilizationService;

import { FacilityLicense } from 'models';
import { inputFieldRegex } from 'utils';

/**
 * A helper function to return if a field-mapping field should be required, based on the value of the clone_facility_id field.
 * @param value the value in the input field
 * @param context Yup context, typed as Any to access the "from" property
 * @returns boolean
 */
// eslint-disable-next-line
export function requireFieldValueWhenCloning(value: string | undefined, context: any) {
	if (context.from.length > 0) {
		const hasCloneSelected = Number.isInteger(parseInt(context.from[context.from.length - 1].value.clone_facility_id));
		const validValue = value ? value?.length > 0 : false;
		if (hasCloneSelected) {
			return true;
		} else {
			return validValue;
		}
	}
	return true;
}

// This function to used to conditionally check for required inputs based on anesthesia license status.
// Since some inputs are conditional, we cannot purely rely on react-hook-forms.
// eslint-disable-next-line
export function requireFieldValueForAnesthesia(value: string | undefined, context: any) {
	if (context.from.length > 0) {
		const hasAnesthesiaSelected =
			parseInt(context.from[context.from.length - 1].value.license_type) === FacilityLicense.anesthesia;
		const hasCloneSelected = Number.isInteger(parseInt(context.from[context.from.length - 1].value.clone_facility_id));
		const validValue = value ? value?.length > 0 : false;
		if ((hasAnesthesiaSelected && validValue) || hasCloneSelected) {
			return true;
		}

		if (hasAnesthesiaSelected && !validValue) {
			return false;
		}
	}
	return true;
}

export function requireFieldValueForScheduleData(value: string | undefined, context: any) {
	if (context.from.length > 0) {
		const mappings = context.from[context.from.length - 1].value.field_mappings;

		// if one of these is provided, then has schedule data must be toggled on
		const toggleChecked =
			mappings.schedule$_case_create_date ||
			mappings.schedule$_surgery_date ||
			mappings.schedule$_procedure_start ||
			mappings.schedule$_procedure_stop ||
			mappings.schedule$_duration ||
			mappings.schedule$_primary_surgeon ||
			mappings.schedule$_facility ||
			mappings.schedule$_room ||
			mappings.schedule$_procedure_description;

		// check if all required fields are provided
		const requiredValues =
			mappings.schedule$_case_create_date &
			mappings.schedule$_surgery_date &
			mappings.schedule$_procedure_start &
			mappings.schedule$_primary_surgeon &
			mappings.schedule$_facility &
			mappings.schedule$_room &
			mappings.schedule$_procedure_description;

		// final check and return
		if (toggleChecked) {
			if (!(requiredValues && (mappings.schedule$_procedure_stop || mappings.schedule$_scheduled_duratio))) {
				return false;
			}
		} else {
			return true;
		}
	}
	return true;
}

/**
 * A helper function to return if a field-mapping field is valid, based on the value of the clone_facility_id field.
 * @param value the value in the input field
 * @param context Yup context, typed as Any to access the "from" property
 * @returns boolean
 */
// eslint-disable-next-line
export function requireValidFieldValueWhenCloning(value: string | undefined, context: any) {
	if (context.from.length > 0) {
		const hasCloneSelected = Number.isInteger(parseInt(context.from[context.from.length - 1].value.clone_facility_id));
		const validValue = value ? inputFieldRegex.test(value) : false;
		if (hasCloneSelected) {
			return true;
		} else {
			return validValue;
		}
	}
	return true;
}

/**
 * A helper function to return if a field-mapping field is valid
 * @param value the value in the input field
 * @param context Yup context, typed as Any to access the "from" property
 * @returns boolean
 */
// eslint-disable-next-line
export function requireValidFieldValue(value: string | undefined) {
	return value ? inputFieldRegex.test(value) : false;
}

/**
 * Counts the number of occurrences of a target string within an array of strings.
 *
 * @param array - An array of strings to search through.
 * @param target - The string whose occurrence count is to be determined.
 * @returns The number of times the target string occurs in the array.
 */
export function countOccurrences(array: string[], target: string): number {
	return array.filter((item) => item === target).length;
}

/**
 * Performs an XOR operation between two arrays and returns elements that exist in one array but not both.
 *
 * @param array1 - The first array.
 * @param array2 - The second array.
 * @returns An array containing elements that exist in one array but not both.
 */
export function xorArrays(array1: number[], array2: number[]): number[] {
	// Combine the arrays
	const combinedArray = [...array1, ...array2];

	// Filter out elements that exist in both arrays
	const uniqueElements = combinedArray.filter((element) => !array1.includes(element) || !array2.includes(element));

	return uniqueElements;
}

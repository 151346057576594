import { format } from 'date-fns';
import React, { ReactHTMLElement, ReactNode, useCallback, useEffect, useState } from 'react';
import {
	Selection,
	VictoryArea,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';
import classNames from 'classnames';
import { ColumnDef, Row } from '@tanstack/react-table';
import { debounce } from 'lodash';

import {
	Button,
	ChartLegend,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	Heatmap,
	LogoOverlay,
	NumberField,
	PageHeading,
	Panel,
	Tooltip,
} from 'components';
import { dropDowns, NurseCoverageResponse, Shift, useAppSelector, useGetNurseCoverageQuery, useSystem } from 'store';
import { FilterContextField, useFilters, useToast } from 'context';
import { DAYS_OF_WEEK, DAYS_OF_WEEK_LIST, fontFamily, numberWithCommas, getColor } from 'utils';
import { ToggleButton } from 'pages/Block/components/ReleaseDialog';
import { ColumnMap } from 'pages/Core';

import { StaffingGrid, StaffingGridItem } from '../components';

const darkGradient = '#4A55A2';
const lightGradient = '#7895CB';
const idealStroke = '#A0BFE0';
const idealFill = 'rgba(160, 191, 224, 0.3)';
const customStroke = '#C4B0FF';
const customRnFill = 'rgba(87, 72, 219, 0.3)';
const customSurgTechFill = 'rgba(120, 106, 247, 0.3)';
const customVarStaffFill = 'rgba(155, 145, 250, 0.3)';
const customIndirectStaffFill = 'rgba(196, 176, 255, 0.3)';
const customFill = 'rgba(56, 44, 168, 0.3)';

interface ToggleStateType {
	medical_ratio: number;
	eight_hour_shift: boolean;
	ten_hour_shift: boolean;
	twelve_hour_shift: boolean;
	fourteen_hour_shift: boolean;
	carousel_day_of_week_selected: string | undefined;
}

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { x: number; y: number; rn_y?: number; surg_tech_y?: number; variable_y?: number; indirect_y?: number };
	id: string;
}> {
	render() {
		const { x2, y2, datum, id } = this.props;
		return (
			<foreignObject x={200} y={200} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-32' id={id}>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Hour of Day</p>
						<p className='text-right text-[0.5em] text-white'>{datum?.x}</p>
					</div>
					{id === 'registered_nurse' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Registered Nurse</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.rn_y}</p>
							</div>
						</div>
					)}
					{id === 'surg_tech' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Surgical Tech</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.surg_tech_y}</p>
							</div>
						</div>
					)}
					{id === 'variable' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Variable Staff</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>
									{datum?.variable_y !== undefined ? Math.round(datum?.variable_y * 100) / 100 : 0}
								</p>
							</div>
						</div>
					)}
					{id === 'indirect' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Indirect Staff</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>
									{datum?.indirect_y !== undefined ? Math.round(datum?.indirect_y * 100) / 100 : 0}
								</p>
							</div>
						</div>
					)}
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	id: string;
	datum?: { allocated: number; automatic_release: number; voluntary_release: number; row_name: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2, id } = this.props;
		return (
			<g>
				<VictoryLabel
					{...this.props}
					verticalAnchor='middle'
					renderInPortal
					dx={5}
					style={{ fontFamily: fontFamily, fontSize: 24 }}
				/>
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} id={id} />}
					style={{ fontSize: 12 }}
				/>
			</g>
		);
	}
}

export function NurseStaffing() {
	// const [mdTableData, setMDTableData] = useState<FacilityUtilizationDetailsResponse['data']>([]);
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data } = useSystem();
	const current_facility = data?.facilities?.find((f) => f.id === selectedFacility);
	const exportData: { day_of_week?: string | undefined }[] = [{}];
	const { createToast } = useToast();
	const [showCost, setShowCost] = useState<boolean>(false);
	const [showProductivity, setShowProductivity] = useState<boolean>(false);

	const legend = [
		{
			label: 'Needs Attention',
			color: '#0b496b',
		},
		{
			label: 'Below Target',
			color: '#3f76c1',
		},
		{
			label: 'On Target',
			color: '#34a5e3',
		},
	];

	// Filters
	const {
		dateRange,
		daysOfWeek,
		rooms,
		addOns,
		serviceLines,
		encounterTypes,
		surgeons,
		anesthesiologists,
		anesthetists,
		percentile,
		heatmapMethod,
		currentPageLoaded,
		dropDowns,
		phase,
		saveDropdown,
		clearFilters,
		metadata,
		resetFilters,
		filtersAreDirty,
		applyFilters,
		salaries,
		mdaCoverRoom,
		turnoverConcurrency,
		wheelsTurnoverTimeThreshold,
		nurseStaffRatios,
		variableStaffWindow,
		indirectStaffWindow,
		filtersAreFetching,
	} = useFilters();

	const toggle_eight_hour_shift =
		dropDowns?.eight_hour_shift !== 'undefined' ? strToBool(dropDowns?.eight_hour_shift) : true;
	const toggle_ten_hour_shift = dropDowns?.ten_hour_shift !== 'undefined' ? strToBool(dropDowns?.ten_hour_shift) : true;
	const toggle_twelve_hour_shift =
		dropDowns?.twelve_hour_shift !== 'undefined' ? strToBool(dropDowns?.twelve_hour_shift) : true;
	const toggle_fourteen_hour_shift =
		dropDowns?.fourteen_hour_shift !== 'undefined' ? strToBool(dropDowns?.fourteen_hour_shift) : false;

	// the following drop down items have their own state because we want instant changes shown, this is done to prevent rubber-banding
	const selectedDayOfWeek = dropDowns?.carousel_day_of_week_selected ?? daysOfWeek?.applied[0];

	const staffingGrid = dropDowns.staffing_grid ?? [];

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/nurse-staffing') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: anesthesiaCoverageOverview,
		isFetching,
		isError,
		error,
	} = useGetNurseCoverageQuery(
		{
			facility_id: selectedFacility,
			filters: {
				days_of_week: daysOfWeek?.applied,
				rooms: rooms?.applied,
				add_ons: addOns?.applied,
				service_lines: serviceLines?.applied,
				surgeons: surgeons?.applied,
				encounter_types: encounterTypes?.applied,
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				heatmap_method: heatmapMethod?.applied.name,
				percentile: percentile?.applied,
				turnover_concurrency: turnoverConcurrency?.applied,
				turnover_threshold: wheelsTurnoverTimeThreshold?.applied,
				variable_staff_ratio: nurseStaffRatios?.applied.variable,
				indirect_staff_ratio: nurseStaffRatios?.applied.indirect,
				indirect_staff_window_start: indirectStaffWindow?.applied.start,
				indirect_staff_window_end: indirectStaffWindow?.applied.end,
				variable_staff_window_start: variableStaffWindow?.applied.start,
				variable_staff_window_end: variableStaffWindow?.applied.end,
				phase: phase?.applied.name,
			},
			toggleStates: {
				medical_ratio: dropDowns.medical_ratio ?? 3,
				eight_hour_shift: toggle_eight_hour_shift,
				ten_hour_shift: toggle_ten_hour_shift,
				twelve_hour_shift: toggle_twelve_hour_shift,
				fourteen_hour_shift: toggle_fourteen_hour_shift,
			},
			staffingGrid: staffingGrid,
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	const getNextDayOfWeek = (selectedDayOfWeek: DAYS_OF_WEEK) => {
		const index = daysOfWeek?.applied.indexOf(selectedDayOfWeek);
		if (index === daysOfWeek?.applied.length - 1) {
			return daysOfWeek?.applied[0];
		} else {
			return daysOfWeek?.applied[index + 1];
		}
	};

	// handler for clicking the "next" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handleNextClick = () => {
		const nextDOW = getNextDayOfWeek(selectedDayOfWeek as DAYS_OF_WEEK);
		dropDowns.update({ ...dropDowns, carousel_day_of_week_selected: nextDOW });
		saveDropdown({
			...dropDowns,
			carousel_day_of_week_selected: nextDOW,
		});
	};

	const getPrevDayOfWeek = (selectedDayOfWeek: DAYS_OF_WEEK) => {
		const index = daysOfWeek?.applied.indexOf(selectedDayOfWeek);
		if (index === 0) {
			return daysOfWeek?.applied[daysOfWeek?.applied.length - 1];
		} else {
			return daysOfWeek?.applied[index - 1];
		}
	};

	// handler for clicking the "prev" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handlePrevClick = () => {
		const nextDOW = getPrevDayOfWeek(selectedDayOfWeek as DAYS_OF_WEEK);
		dropDowns.update({ ...dropDowns, carousel_day_of_week_selected: nextDOW });
		saveDropdown({
			...dropDowns,
			carousel_day_of_week_selected: nextDOW,
		});
	};

	// When data from the endpoint changes or an error is encountered, update the
	// table data appropriately.
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}

			return;
		}
	}, [anesthesiaCoverageOverview, error, createToast]);

	// calculate y axis
	let rrDataMaxY = 0;

	let totalStaffDataMaxY = 0;

	const totalStaffSalary =
		salaries.applied.registered_nurse +
		salaries.applied.surgical_tech +
		salaries.applied.variable_staff +
		salaries.applied.indirect_staff;

	for (let i = 0; i < daysOfWeek?.applied.length; i++) {
		const rr_max =
			Math.ceil(
				Math.max(...(anesthesiaCoverageOverview?.data.total_staff_rr[daysOfWeek?.applied[i]]?.map((d) => d.y) ?? [1])) + 1
			) ?? 0;

		const total_staff_max =
			Math.ceil(
				Math.max(
					...(anesthesiaCoverageOverview?.data.total_staff_coverage[daysOfWeek?.applied[i]]?.map((d) => d.y) ?? [1])
				) + 1
			) ?? 0;

		if (rr_max > rrDataMaxY) {
			rrDataMaxY = total_staff_max + 1;
		}

		if (total_staff_max > totalStaffDataMaxY) {
			totalStaffDataMaxY = total_staff_max + 1;
		}
	}

	// // Overlays users custom staffing grid
	// const userDefinedStaffingLineCRNA = new Array(23).fill(0);

	// dropDowns.staffing_grid?.forEach((staffing) => {
	// 	const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
	// 	for (let i = 0; i < hours.length; i++) {
	// 		userDefinedStaffingLineCRNA[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];

	// 		if (crnaDataMaxY < staffing[selectedDayOfWeek as keyof StaffingGridItem]) {
	// 			crnaDataMaxY = staffing[selectedDayOfWeek as keyof StaffingGridItem] + 1;
	// 		}
	// 	}
	// });
	const userDefinedRnStaffingLine = new Array(23).fill(0);
	const userDefinedSurgTechStaffingLine = new Array(23).fill(0);
	const userDefinedVarStaffStaffingLine = new Array(23).fill(0);
	const userDefinedIndirectStaffStaffingLine = new Array(23).fill(0);
	const userDefinedStaffingLine = new Array(23).fill(0);

	anesthesiaCoverageOverview?.data.rn_staffing_grid?.forEach((staffing: any) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedRnStaffingLine[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];
		}
	});

	anesthesiaCoverageOverview?.data.surg_tech_staffing_grid?.forEach((staffing: any) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedSurgTechStaffingLine[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];
		}
	});

	anesthesiaCoverageOverview?.data.var_staff_staffing_grid?.forEach((staffing: any) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedVarStaffStaffingLine[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];
		}
	});

	anesthesiaCoverageOverview?.data.indirect_staff_staffing_grid?.forEach((staffing: any) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedIndirectStaffStaffingLine[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];
		}
	});

	anesthesiaCoverageOverview?.data.total_staff_staffing_grid?.forEach((staffing: any) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedStaffingLine[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];

			if (totalStaffDataMaxY < staffing[selectedDayOfWeek as keyof StaffingGridItem]) {
				totalStaffDataMaxY = staffing[selectedDayOfWeek as keyof StaffingGridItem] + 1;
			}
		}
	});

	const role = data?.user.role;
	const costAccessEnabled = role === 1 || role === 4;

	const disableCostButton = !costAccessEnabled;

	return (
		<>
			<PageHeading>Nurse Staffing</PageHeading>
			<div className='flex justify-end pb-3 overflow-auto'>
				<div>
					<Tooltip
						content={
							disableCostButton
								? 'To enable this button, add salary information for providers under Anesthesia Settings'
								: showCost
								? 'Display FTE counts in the Coverage by Shift Type table(s)'
								: 'Display Staffing cost in the Coverage by Shift Type table(s)'
						}
					>
						<Button
							sizeX='square'
							sizeY='md'
							variant={showCost ? 'primary' : 'primary-ghost'}
							className='mr-2'
							onClick={() => {
								// if !showCost is true then we will be updating the value from false to true after the conditional
								// in the conditional, turn showProductivity off/set to false since showCost will be turned on
								// we can't turn both toggles (showCost and showProductivity) on at once
								if (!showCost === true) {
									setShowProductivity(false);
								}
								setShowCost(!showCost);
							}}
							disabled={showProductivity}
						>
							<span className='material-symbols-outlined'>attach_money</span>
						</Button>
					</Tooltip>
					<Tooltip
						content={
							disableCostButton
								? 'To enable this button, add salary information for providers under Anesthesia Settings'
								: showCost
								? 'Display FTE counts in the Coverage by Shift Type table(s)'
								: 'Display Productivity by hour in the Staff Productivity table(s)'
						}
					>
						<Button
							sizeX='square'
							sizeY='md'
							variant={showProductivity ? 'primary' : 'primary-ghost'}
							className='mr-2'
							onClick={() => {
								// if !showProductivity is true then we will be updating the value from false to true after the conditional
								// in the conditional, turn showCost off/set to false since showProductivity will be turned on
								// again, we can't turn both toggles (showCost and showProductivity) on at once
								if (!showProductivity === true) {
									setShowCost(false);
								}
								setShowProductivity(!showProductivity);
							}}
							disabled={showCost}
						>
							<span className='material-symbols-outlined'>productivity</span>
						</Button>
					</Tooltip>
				</div>
				<StaffingGrid>
					<Button sizeX='md' sizeY='md' variant='primary-ghost' className='mr-2'>
						<div className='flex items-center'>
							<span className='material-symbols-outlined mr-2'>calendar_view_month</span>
							<p>Custom</p>
						</div>
					</Button>
				</StaffingGrid>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields
						fields={[
							'dateRange',
							'daysOfWeek',
							'rooms',
							'serviceLines',
							'addOns',
							'encounterTypes',
							// 'surgeons',
							// 'anesthesiologists',
							// 'anesthetists',
							'heatmapMethod',
							'percentile',
							'salaries',
							'turnoverConcurrency',
							'wheelsTurnoverTimeThreshold',
							'nurseStaffRatios',
							'variableStaffWindow',
							'indirectStaffWindow',
							'phase',
						]}
						dateRangeLimit={1}
					/>
				</Drawer>
				<div>
					<Button sizeX='md' sizeY='md' variant='primary' disabled>
						<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData ?? []}>
							Export CSV
						</ExportButton>
					</Button>
				</div>
			</div>
			<Panel
				unStyledTitle={
					<div className='flex flex-col justify-between w-[40em] xl:flex-row'>
						<div className='flex justify-between'>
							<div>
								<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Shift Type Options</p>
								<div className='flex mr-5'>
									<ShiftToggleButton
										text='8 hr'
										toggle={(isActive) => {
											if (isActive || toggle_ten_hour_shift || toggle_twelve_hour_shift || toggle_fourteen_hour_shift) {
												dropDowns.update({
													...dropDowns,
													eight_hour_shift: boolToStr(isActive),
												});
												saveDropdown({
													...dropDowns,
													eight_hour_shift: boolToStr(isActive),
												});
											}
										}}
										isActive={toggle_eight_hour_shift}
										firstItem={true}
									/>

									<ShiftToggleButton
										text='10 hr'
										toggle={(isActive) => {
											if (toggle_eight_hour_shift || isActive || toggle_twelve_hour_shift || toggle_fourteen_hour_shift) {
												dropDowns.update({
													...dropDowns,
													ten_hour_shift: boolToStr(isActive),
												});
												saveDropdown({
													...dropDowns,
													ten_hour_shift: boolToStr(isActive),
												});
											}
										}}
										isActive={toggle_ten_hour_shift}
									/>
									<ShiftToggleButton
										text='12 hr'
										toggle={(isActive) => {
											dropDowns.update({
												...dropDowns,
												twelve_hour_shift: boolToStr(isActive),
											});
											saveDropdown({
												...dropDowns,
												twelve_hour_shift: boolToStr(isActive),
											});
										}}
										isActive={toggle_twelve_hour_shift}
									/>
									<ShiftToggleButton
										text='14 hr'
										toggle={(isActive) => {
											dropDowns.update({
												...dropDowns,
												fourteen_hour_shift: boolToStr(isActive),
											});
											saveDropdown({
												...dropDowns,
												fourteen_hour_shift: boolToStr(isActive),
											});
										}}
										isActive={toggle_fourteen_hour_shift}
									/>
								</div>
							</div>
						</div>
					</div>
				}
				headerContentRight={
					<div className='flex mt-2'>
						<div className='flex text-center border border-blue-500 items-center justify-center gap-0 mr-4'>
							<Button sizeX='square' sizeY='sm' variant='transparent' className='text-blue-500' onClick={handlePrevClick}>
								<span className='material-symbol h-fit'>navigate_before</span>
							</Button>
							<div className='text-p2 text-blue-500 w-24'>{selectedDayOfWeek}</div>
							<Button sizeX='square' sizeY='sm' variant='transparent' className='text-blue-500' onClick={handleNextClick}>
								<span className='material-symbol h-fit'>navigate_next</span>
							</Button>
						</div>
						<div className='flex text-center bg-blue-500 items-center justify-center gap-0 rounded-md'>
							<span className='text-b3 font-semibold px-6 text-white'>
								{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
							</span>
						</div>
					</div>
				}
				goToHelpID='heatmap'
			>
				{(isFetching || skipRequest || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}
				<Heatmap
					title={''}
					showFullYAxisName={true}
					// colorScheme={props.colorScheme}
					// customCellPadding={props.customPadding}
					datumFormat={(datum) => String(Math.round(parseFloat(String(datum))))}
					axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
					data={
						isError
							? []
							: (anesthesiaCoverageOverview?.data.rr.length
									? [anesthesiaCoverageOverview?.data.rr[daysOfWeek?.applied.indexOf(selectedDayOfWeek as DAYS_OF_WEEK)]]
									: anesthesiaCoverageOverview?.data.rr) ?? []
					}
					emptyMessage='This heatmap has no data to display based on the provided filters.'
				/>
				<div className='flex'>
					<div className='basis-full'>
						<VictoryChart domain={{ y: [0, totalStaffDataMaxY] }} containerComponent={<VictoryVoronoiContainer />}>
							<VictoryAxis
								dependentAxis
								label='Est. No. of Staff'
								tickCount={6}
								tickValues={fillArray(0, totalStaffDataMaxY)}
								style={{
									axis: { stroke: 'hsl(0 0% 90%)' },
									grid: { stroke: 'hsl(0 0% 90%)' },
									tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
									axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
								}}
							/>
							<VictoryAxis
								label='Hour of Day'
								tickCount={12}
								tickValues={fillArray(0, 23)}
								style={{
									axis: { stroke: 'hsl(0 0% 90%)' },
									tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
									axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
								}}
							/>
							<defs>
								<linearGradient id='myGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
									<stop offset='0%' stopColor={lightGradient} />
									<stop offset='100%' stopColor={darkGradient} />
								</linearGradient>
							</defs>
							<VictoryArea
								style={{
									data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
									parent: { border: '1px solid ##bcbcbc' },
								}}
								data={
									anesthesiaCoverageOverview?.data.rn_coverage[selectedDayOfWeek]
										? anesthesiaCoverageOverview?.data.rn_coverage[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													rn_y: d.y,
												};
										  })
										: []
								}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'registered_nurse'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
									parent: { border: '1px solid ##bcbcbc' },
								}}
								data={
									anesthesiaCoverageOverview?.data.surg_tech_coverage[selectedDayOfWeek]
										? anesthesiaCoverageOverview?.data.surg_tech_coverage[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													surg_tech_y: d.y - anesthesiaCoverageOverview?.data.rn_coverage[selectedDayOfWeek][i].y ?? d.y,
												};
										  })
										: []
								}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'surg_tech'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
									parent: { border: '1px solid ##bcbcbc' },
								}}
								data={
									anesthesiaCoverageOverview?.data.var_staff_coverage[selectedDayOfWeek]
										? anesthesiaCoverageOverview?.data.var_staff_coverage[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													variable_y: d.y - anesthesiaCoverageOverview?.data.surg_tech_coverage[selectedDayOfWeek][i].y ?? d.y,
												};
										  })
										: []
								}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'variable'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
									parent: { border: '1px solid ##bcbcbc' },
								}}
								data={
									anesthesiaCoverageOverview?.data.indirect_staff_coverage[selectedDayOfWeek]
										? anesthesiaCoverageOverview?.data.indirect_staff_coverage[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													indirect_y:
														anesthesiaCoverageOverview?.data.total_staff_coverage[selectedDayOfWeek][i].y -
															anesthesiaCoverageOverview?.data.var_staff_coverage[selectedDayOfWeek][i].y ?? d.y,
												};
										  })
										: []
								}
								interpolation='stepAfter'
								labels={() => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'indirect'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
									parent: { border: '1px solid ##bcbcbc' },
								}}
								data={
									anesthesiaCoverageOverview?.data.total_staff_coverage[selectedDayOfWeek]
										? anesthesiaCoverageOverview?.data.total_staff_coverage[selectedDayOfWeek]
										: []
								}
								interpolation='stepAfter'
								labels={() => ``}
								labelComponent={<CustomLabel id={'ideal'} />}
							/>

							<VictoryLabel
								text='Ideal Staff Coverage'
								textAnchor='middle'
								x={225}
								y={18}
								style={{
									fontFamily: 'Inter',
									fontSize: 10,
									fill: '#8395a7',
								}}
							/>
						</VictoryChart>
					</div>
					<div className='basis-full'>
						<VictoryChart domain={{ y: [0, totalStaffDataMaxY] }} containerComponent={<VictoryVoronoiContainer />}>
							<VictoryAxis
								dependentAxis
								label='Est. No. of Staff'
								tickCount={6}
								tickValues={fillArray(0, totalStaffDataMaxY)}
								style={{
									axis: { stroke: 'hsl(0 0% 90%)' },
									grid: { stroke: 'hsl(0 0% 90%)' },
									tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
									axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
								}}
							/>
							<VictoryAxis
								label='Hour of Day'
								tickCount={12}
								tickValues={fillArray(0, 23)}
								style={{
									axis: { stroke: 'hsl(0 0% 90%)' },
									tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
									axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
								}}
							/>
							<defs>
								<linearGradient id='myGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
									<stop offset='0%' stopColor={lightGradient} />
									<stop offset='100%' stopColor={darkGradient} />
								</linearGradient>
							</defs>
							<VictoryArea
								style={{
									data: { stroke: customStroke, strokeWidth: 1.5, fill: customRnFill },
									parent: { border: '1px solid #bcbcbc' },
								}}
								data={userDefinedRnStaffingLine.slice(0, 24).map((d, i) => {
									return {
										x: i,
										y: d,
										rn_y: d,
									};
								})}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'registered_nurse'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: customStroke, strokeWidth: 1.5, fill: customSurgTechFill },
									parent: { border: '1px solid #bcbcbc' },
								}}
								data={userDefinedSurgTechStaffingLine.slice(0, 24).map((d, i) => {
									return {
										x: i,
										y: d,
										surg_tech_y: d - userDefinedRnStaffingLine[i] ?? d,
									};
								})}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'surg_tech'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: customStroke, strokeWidth: 1.5, fill: customVarStaffFill },
									parent: { border: '1px solid #bcbcbc' },
								}}
								data={userDefinedVarStaffStaffingLine.slice(0, 24).map((d, i) => {
									return {
										x: i,
										y: d,
										variable_y: d - userDefinedSurgTechStaffingLine[i] ?? d,
									};
								})}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'variable'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: customStroke, strokeWidth: 1.5, fill: customIndirectStaffFill },
									parent: { border: '1px solid #bcbcbc' },
								}}
								data={userDefinedIndirectStaffStaffingLine.slice(0, 24).map((d, i) => {
									return {
										x: i,
										y: d,
										indirect_y: userDefinedStaffingLine[i] - userDefinedVarStaffStaffingLine[i] ?? d,
									};
								})}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'indirect'} />} />}
							/>
							<VictoryArea
								style={{
									data: { stroke: customStroke, strokeWidth: 1.5, fill: customFill },
									parent: { border: '1px solid #bcbcbc' },
								}}
								data={userDefinedStaffingLine.slice(0, 24).map((d, i) => {
									return {
										x: i,
										total_y: d,
									};
								})}
								interpolation='stepAfter'
								labels={({ datum }: any) => ``}
								labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'total'} />} />}
							/>

							<VictoryLabel
								text='Custom Staff Coverage'
								textAnchor='middle'
								x={225}
								y={18}
								style={{
									fontFamily: 'Inter',
									fontSize: 10,
									fill: '#8395a7',
								}}
							/>
						</VictoryChart>
					</div>
				</div>
			</Panel>
			<div className='py-4'></div>
			{!showProductivity ? (
				<>
					<DataTable
						title='RN Coverage by Shift Type'
						disablePagination
						omittedColumns={['model_type']}
						disableRowCounter
						addCommaToSums={showCost}
						addPrefixToSums={showCost ? '$' : ''}
						goToHelpID={'fliprooms'}
						headerContentCenterAlignment='left'
						columns={[
							{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
							{
								accessorKey: 'shift_fte',
								header: 'Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.registered_nurse ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: showProductivity
										? numberWithCommas(`$${((salaries.applied.registered_nurse ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: row.original.shift_fte,
							},
							{
								accessorKey: 'after_hours_fte',
								header: 'Non-Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.registered_nurse ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
										: row.original.after_hours_fte,
							},
							{
								accessorKey: 'total_fte',
								header: 'Total FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.registered_nurse ?? 1) * row.original.total_fte).toFixed(0)}`)
										: row.original.total_fte,
							},
						]}
						data={anesthesiaCoverageOverview?.data.rn_fte_coverage_rollup ?? []}
						rowSubview={(row) => (
							<>
								<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
									RN FTE Details
								</h3>
								<DataTable
									title='RN FTE Details'
									minimalStyle
									omittedColumns={['day_of_week', 'day_of_week_int']}
									defaultSort={{ id: 'day_of_week_int', desc: false }}
									displayRowSums
									addCommaToSums={showCost}
									addPrefixToSums={showCost ? '$' : ''}
									displayColSums
									disablePagination
									disableRowCounter
									goToHelpID={'fliprooms'}
									headerContentCenterAlignment='left'
									columns={findColumnHeaders(
										row.original.details,
										showCost ? salaries.applied.registered_nurse ?? 1 : 1,
										'CRNA'
									)}
									data={addSalaryToValues(row.original.details, showCost ? salaries.applied.registered_nurse ?? 1 : 1) ?? []}
								/>
							</>
						)}
					/>
					<div className='py-4'></div>
					<DataTable
						title='Surgical Tech Coverage by Shift Type'
						tooltipContent={``}
						goToHelpID={'heatmap'}
						headerContentCenterAlignment='left'
						columns={[
							{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
							{
								accessorKey: 'shift_fte',
								header: 'Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.surgical_tech ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: row.original.shift_fte,
							},
							{
								accessorKey: 'after_hours_fte',
								header: 'Non-Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.surgical_tech ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
										: row.original.after_hours_fte,
							},
							{
								accessorKey: 'total_fte',
								header: 'Total FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.surgical_tech ?? 1) * row.original.total_fte).toFixed(0)}`)
										: row.original.total_fte,
							},
						]}
						data={anesthesiaCoverageOverview?.data.surg_tech_fte_coverage_rollup ?? []}
						disablePagination
						disableRowCounter
						rowSubview={(row) => (
							<>
								<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
									Surgical Tech FTE Details
								</h3>
								<DataTable
									title='Surgical Tech FTE Details'
									tooltipContent={``}
									defaultSort={{ id: 'day_of_week_int', desc: false }}
									omittedColumns={['day_of_week', 'day_of_week_int']}
									displayRowSums
									displayColSums
									addCommaToSums={showCost}
									addPrefixToSums={showCost ? '$' : ''}
									minimalStyle
									goToHelpID={'heatmap'}
									headerContentCenterAlignment='left'
									columns={findColumnHeaders(row.original.details, showCost ? salaries.applied.surgical_tech ?? 1 : 1, 'CRNA')}
									data={addSalaryToValues(row.original.details, showCost ? salaries.applied.surgical_tech ?? 1 : 1) ?? []}
									disablePagination
									disableRowCounter
								/>
							</>
						)}
					/>
					<div className='py-4'></div>
					<DataTable
						title='Variable Staff Coverage by Shift Type'
						tooltipContent={``}
						goToHelpID={'heatmap'}
						headerContentCenterAlignment='left'
						columns={[
							{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
							{
								accessorKey: 'shift_fte',
								header: 'Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.variable_staff ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: row.original.shift_fte,
							},
							{
								accessorKey: 'after_hours_fte',
								header: 'Non-Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.variable_staff ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
										: row.original.after_hours_fte,
							},
							{
								accessorKey: 'total_fte',
								header: 'Total FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.variable_staff ?? 1) * row.original.total_fte).toFixed(0)}`)
										: row.original.total_fte,
							},
						]}
						data={anesthesiaCoverageOverview?.data.var_staff_fte_coverage_rollup ?? []}
						disablePagination
						disableRowCounter
						rowSubview={(row) => (
							<>
								<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
									Variable Staff FTE Details
								</h3>
								<DataTable
									title='Variable Staff FTE Details'
									tooltipContent={``}
									defaultSort={{ id: 'day_of_week_int', desc: false }}
									omittedColumns={['day_of_week', 'day_of_week_int']}
									displayRowSums
									displayColSums
									addCommaToSums={showCost}
									addPrefixToSums={showCost ? '$' : ''}
									minimalStyle
									goToHelpID={'heatmap'}
									headerContentCenterAlignment='left'
									columns={findColumnHeaders(row.original.details, showCost ? salaries.applied.variable_staff ?? 1 : 1, 'CRNA')}
									data={addSalaryToValues(row.original.details, showCost ? salaries.applied.variable_staff ?? 1 : 1) ?? []}
									disablePagination
									disableRowCounter
								/>
							</>
						)}
					/>
					<div className='py-4'></div>
					<DataTable
						title='Indirect Staff Coverage by Shift Type'
						tooltipContent={``}
						goToHelpID={'heatmap'}
						headerContentCenterAlignment='left'
						columns={[
							{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
							{
								accessorKey: 'shift_fte',
								header: 'Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.indirect_staff ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: row.original.shift_fte,
							},
							{
								accessorKey: 'after_hours_fte',
								header: 'Non-Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.indirect_staff ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
										: row.original.after_hours_fte,
							},
							{
								accessorKey: 'total_fte',
								header: 'Total FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((salaries.applied.indirect_staff ?? 1) * row.original.total_fte).toFixed(0)}`)
										: row.original.total_fte,
							},
						]}
						data={anesthesiaCoverageOverview?.data.indirect_staff_fte_coverage_rollup ?? []}
						disablePagination
						disableRowCounter
						rowSubview={(row) => (
							<>
								<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
									Indirect Staff FTE Details
								</h3>
								<DataTable
									title='Indirect Staff FTE Details'
									tooltipContent={``}
									defaultSort={{ id: 'day_of_week_int', desc: false }}
									omittedColumns={['day_of_week', 'day_of_week_int']}
									displayRowSums
									displayColSums
									addCommaToSums={showCost}
									addPrefixToSums={showCost ? '$' : ''}
									minimalStyle
									goToHelpID={'heatmap'}
									headerContentCenterAlignment='left'
									columns={findColumnHeaders(row.original.details, showCost ? salaries.applied.indirect_staff ?? 1 : 1, 'CRNA')}
									data={addSalaryToValues(row.original.details, showCost ? salaries.applied.indirect_staff ?? 1 : 1) ?? []}
									disablePagination
									disableRowCounter
								/>
							</>
						)}
					/>
					<div className='py-4'></div>
					<DataTable
						title='Total Staff Coverage by Shift Type'
						tooltipContent={``}
						goToHelpID={'heatmap'}
						headerContentCenterAlignment='left'
						columns={[
							{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
							{
								accessorKey: 'shift_fte',
								header: 'Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((totalStaffSalary ?? 1) * row.original.shift_fte).toFixed(0)}`)
										: row.original.shift_fte,
							},
							{
								accessorKey: 'after_hours_fte',
								header: 'Non-Primetime FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((totalStaffSalary ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
										: row.original.after_hours_fte,
							},
							{
								accessorKey: 'total_fte',
								header: 'Total FTE',
								enableGlobalFilter: false,
								cell: ({ row }) =>
									showCost
										? numberWithCommas(`$${((totalStaffSalary ?? 1) * row.original.total_fte).toFixed(0)}`)
										: row.original.total_fte,
							},
						]}
						data={anesthesiaCoverageOverview?.data.total_staff_fte_coverage_rollup ?? []}
						disablePagination
						disableRowCounter
						rowSubview={(row) => (
							<>
								<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
									Total Staff FTE Details
								</h3>
								<DataTable
									title='Total Staff FTE Details'
									tooltipContent={``}
									defaultSort={{ id: 'day_of_week_int', desc: false }}
									omittedColumns={['day_of_week', 'day_of_week_int']}
									displayRowSums
									displayColSums
									addCommaToSums={showCost}
									addPrefixToSums={showCost ? '$' : ''}
									minimalStyle
									goToHelpID={'heatmap'}
									headerContentCenterAlignment='left'
									columns={findColumnHeaders(row.original.details, showCost ? totalStaffSalary ?? 1 : 1, 'CRNA')}
									data={addSalaryToValues(row.original.details, showCost ? totalStaffSalary ?? 1 : 1) ?? []}
									disablePagination
									disableRowCounter
								/>
							</>
						)}
					/>
				</>
			) : (
				<DataTable
					title='Total Staff Productivity'
					tooltipContent={`Productivity is defined as worked staff hours over surgical case minutes`}
					goToHelpID={'heatmap'}
					headerContentCenterAlignment='left'
					headerContentRight={<ChartLegend options={legend} />}
					columns={getProductivityColumnHeaders(anesthesiaCoverageOverview?.data.concurrency_output_minutes ?? [])}
					data={anesthesiaCoverageOverview?.data.concurrency_output_minutes ?? []}
					disablePagination
					disableRowCounter
					rowSubview={(row) => (
						<>
							<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
								Total Staff Productivity Details
							</h3>
							<DataTable
								title='Total Staff Productivity Details'
								tooltipContent={``}
								defaultSort={{ id: 'day_of_week_int', desc: false }}
								omittedColumns={['day_of_week', 'day_of_week_int']}
								addCommaToSums={showCost}
								addPrefixToSums={showCost ? '$' : ''}
								minimalStyle
								goToHelpID={'heatmap'}
								headerContentCenterAlignment='left'
								columns={findColumnHeaders(row.original.details, showCost ? totalStaffSalary ?? 1 : 1, 'CRNA')}
								data={row.original.details ?? []}
								disablePagination
								disableRowCounter
							/>
						</>
					)}
				/>
			)}
		</>
	);
}

interface ConcurrencyOutputMinutesResponse {
	[key: number]: number;
	overall: number;
	model_type: string;
	details: { [key: number]: number; day_of_week: string; day_of_week_int: number }[];
}

function getFields(list: number[], field: number) {
	return list.reduce(function (carry, item) {
		if (typeof item === 'object' && field in item) {
			carry.push(item[field]);
		}
		return carry;
	}, []);
}

function getProductivityColumnHeaders(data: ConcurrencyOutputMinutesResponse[]) {
	const carry: intDef[] = [{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false }];

	for (let i = 0; i < 24; i++) {
		carry.push({
			accessorKey: String(i),
			header: String(i),
			enableGlobalFilter: false,
			cell: ({ row }) => {
				const color = classifyTextColorCoding(`${row.original[i]}`);
				return <p className={color}>{`${row.original[i]}`}</p>;
			},
		});
	}
	carry.push({
		accessorKey: 'overall',
		header: 'Overall',
		enableGlobalFilter: false,
		cell: ({ row }) => {
			const color = classifyTextColorCoding(`${row.original['overall']}`);
			return <p className={color}>{`${row.original['overall']}`}</p>;
		},
	});
	return carry;
}

interface intDef {
	header: string;
	accessorKey: string;
	enableGlobalFilter?: boolean;
	meta?: { headerClass: string; bodyClass: string };
	cell?: ({ row }: any) => any;
}

function addSalaryToValues(obj: Shift[], salary: number) {
	if (salary === 1) {
		return obj;
	}

	const ignoredColumns = ['Row Sum', 'day_of_week', 'day_of_week_int'];

	return obj.map((elem) => {
		const keys = Object.keys(elem).filter((key) => !ignoredColumns.includes(key));
		const newObj = { ...elem };
		for (let i = 0; i < keys.length; i++) {
			newObj[keys[i]] = parseInt(`${Number(elem[keys[i]]) * salary}`);
		}
		return newObj;
	});
}

function classifyTextColorCoding(x_str: string) {
	const x = parseFloat(x_str);
	if (x > 0.25) {
		return 'text-sky-950 font-bold';
	} else if (x <= 0.25 && x >= 0.14) {
		return 'text-sky-800/80 font-bold';
	} else if (x < 0.14 && x > 0) {
		return 'text-sky-400/70 font-bold';
	} else if (x === 0) {
		return 'text-gray-500 font-bold';
	} else {
		return '';
	}
}

function findColumnHeaders(obj: Shift[] | undefined, salary: number, tableType: string) {
	if (!obj) {
		return [];
	}

	const carry: intDef[] = [
		{
			header: 'Day of Week',
			accessorKey: 'day_of_week_int',
			enableGlobalFilter: true,
			cell: ({ row }: any) => `${row?.original.day_of_week}`,
		},
	];

	if (tableType === 'CRNA') {
		for (const property in obj[0]) {
			if (!property.includes('day_of_week')) {
				if (property !== 'Row Sum') {
					carry.push({
						header: property,
						accessorKey: property,
						enableGlobalFilter: true,
						cell: ({ row }: any) =>
							salary > 1 ? (
								`$${numberWithCommas(`${parseFloat(row?.original[property]).toFixed(2)}`)}`
							) : (
								<p className={classifyTextColorCoding(`${row?.original[property]}`)}>
									{parseFloat(row?.original[property]).toFixed(2)}
								</p>
							),
					});
				}
			}
		}
	} else {
		for (const property in obj[0]) {
			if (!property.includes('day_of_week')) {
				if (property !== 'Row Sum') {
					carry.push({
						header: property,
						accessorKey: property,
						enableGlobalFilter: true,
						cell: ({ row }: any) =>
							salary > 1 ? `$${numberWithCommas(`${row?.original[property]}`)}` : `${row?.original[property]}`,
					});
				}
			}
		}
	}
	return carry.sort(compare);
}

function compare(a: intDef, b: intDef) {
	// Split the 'time' string into two parts
	const aTimes = a.header.split('-').map(Number);
	const bTimes = b.header.split('-').map(Number);

	// Compare the first part
	if (aTimes[0] < bTimes[0]) {
		return -1;
	}
	if (aTimes[0] > bTimes[0]) {
		return 1;
	}

	// If the first parts are equal, compare the second part
	if (aTimes[1] < bTimes[1]) {
		return -1;
	}
	if (aTimes[1] > bTimes[1]) {
		return 1;
	}

	return 0;
}

function ShiftToggleButton({
	text,
	toggle,
	activeText,
	bgColor,
	isActive,
	firstItem,
	disabled,
}: {
	text: string;
	toggle: (toggleState: boolean) => void;
	activeText?: string;
	bgColor?: 'blue' | 'green';
	isActive: boolean;
	firstItem?: boolean;
	disabled?: boolean;
}) {
	return (
		<div
			onClick={() => {
				toggle(!isActive);
			}}
			className={classNames(
				{
					'cursor-pointer bg-transparent text-blue-500': !isActive && !bgColor && !disabled,
					'cursor-pointer bg-blue-500 text-white': isActive && !bgColor && !disabled,
					'cursor-pointer bg-transparent text-green-600': !isActive && bgColor === 'green',
					'cursor-pointer bg-green-600 text-white': isActive && bgColor === 'green',
					'cursor-pointer border border-blue-400 ': bgColor === undefined || bgColor === 'blue',
					'cursor-pointer border border-green-600 ': bgColor === 'green',
					'cursor-pointer border-l-0': !firstItem,
					'cursor-not-allowed opacity-80 text-gray-500 bg-gray-100': disabled,
				},
				'text-p3 px-3 py-1 text-center select-none whitespace-nowrap'
			)}
		>
			{isActive && activeText ? activeText : text}
		</div>
	);
}

function LegendToggleButton({
	text,
	toggle,
	activeText,
	disabled,
	bgColor,
	isActive,
	firstItem,
	color,
}: {
	text: string;
	toggle: (toggleState: boolean) => void;
	activeText?: string;
	bgColor?: 'blue' | 'green';
	isActive: boolean;
	firstItem?: boolean;
	color?: ReactNode;
	disabled?: boolean;
}) {
	return (
		<div
			onClick={() => {
				toggle(!isActive);
			}}
			className={classNames(
				{
					'border border-blue-500 border-b-1 text-black': !isActive && !bgColor && !disabled,
					'border border-b-blue-500 border-b-4 text-black': isActive && !bgColor && !disabled,
					'bg-green-600 text-white': isActive && bgColor === 'green',
					'border border-blue-400 ': bgColor === undefined || bgColor === 'blue',
					'border border-green-600 ': bgColor === 'green',
					'border-l-0': !firstItem,
					'cursor-not-allowed opacity-80 text-gray-500 bg-gray-100': disabled,
				},
				'text-p3 px-3 py-1 cursor-pointer text-center select-none flex items-center justify-center h-[2.1em]'
			)}
		>
			<div className='flex items-center'>
				{color}
				<p>{text}</p>
			</div>
		</div>
	);
}

function strToBool(s: string | undefined): boolean {
	return s === 'true';
}

function boolToStr(b: boolean): string {
	return b ? 'true' : 'false';
}

function fillArray(n: number, m: number) {
	return Array.from({ length: m - n + 1 }, (value, index) => n + index);
}

export default NurseStaffing;

import { useState } from 'react';

import { Button, ButtonLink, DataTable, LogoOverlay, Tooltip, UserSettingsDialog } from 'components';
import { FacilityLicense, reverseLicenseMap, UserRole } from 'models';
import { daysDifference, getFacility, getFacilityName, getLicenseNames, getRoleDesignationText, userIs } from 'utils';
import {
	getQueryError,
	handleApiResponse,
	useAppSelector,
	useDeleteUserMutation,
	useGetUsersQuery,
	useReInviteUserMutation,
	useRequestPasswordResetAdminMutation,
	useSystem,
} from 'store';
import { useAlert, useToast } from 'context';

export function AccountSettings() {
	const toast = useToast();
	const { getAlertResponse } = useAlert();
	// this keeps track of the id of the user being deleted
	const [userBeingDeleted, setUserBeingDeleted] = useState<number | null>(null);
	const [sendPasswordResetEmailAdmin] = useRequestPasswordResetAdminMutation();

	// get all needed data from state/API
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data } = useSystem();
	const { data: usersData, isLoading } = useGetUsersQuery({
		healthsystem_id: selectedSystem,
	});
	const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
	const [resendInviteEmail, { isLoading: isResending }] = useReInviteUserMutation();
	const facilities = data?.facilities;

	const facilityLicenseDetails =
		facilities?.map((facility) => ({
			name: getFacilityName(facility),
			license: facility.license,
			roomsCount: facility.rooms.length,
			rooms: facility.rooms,
		})) ?? [];

	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);
	const isMerlinAdmin = userIs([UserRole.admin], data?.user);

	const sendResetEmail = async (email: string) => {
		const postPayload = {
			email,
		};

		const response = await sendPasswordResetEmailAdmin(postPayload);

		handleApiResponse(response, {
			success: (payload) => {
				toast.createToast({
					title: `Password reset request sent`,
					variant: 'success',
				});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const resendInvite = async (id: number) => {
		const postPayload = {
			id,
		};
		const response = await resendInviteEmail(postPayload);
		handleApiResponse(response, {
			success: (payload) => {
				toast.createToast({
					title: `Invite email resent`,
					variant: 'success',
				});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	// Helper function to check if user invite is expired hardcoded to 7 days.
	const isUserInviteExpired = (invited_date: Date): boolean => {
		const age_of_invite = daysDifference(invited_date);

		if (age_of_invite > 5) {
			// we check for 6 days instead of 7 because of intraday time differences.
			return true;
		}
		return false;
	};

	return (
		<>
			<div className='pt-4 relative'>
				{isLoading && <LogoOverlay backgroundColor='white' />}
				{facilities && (
					<div className='relative'>
						<DataTable
							title={isAdministrator ? 'Add & Manage Users' : 'Merlin Users'}
							tooltipContent={'Use this table to see which users have been added into Merlin'}
							goToHelpID='manageusers'
							headerContentRight={
								isAdministrator ? (
									<UserSettingsDialog facilities={facilities}>
										<Button sizeX='sm'>
											<span className='material-symbol'>person_add</span>
											Add User
										</Button>
									</UserSettingsDialog>
								) : undefined
							}
							columns={[
								{
									header: 'Status',
									enableGlobalFilter: false,
									accessorKey: 'role',
									cell: ({ row }) => {
										// either use is from original okta flow or activated using new flow
										if (!row.original.inserted_at || row.original.updated_at) {
											return (
												<Tooltip content={`User responded to invitation and activated account`}>
													<p className='text-green-500'>Active</p>
												</Tooltip>
											);
										}

										// user has no updated_at timestamp, so we check age of the inserted_at timestamp
										if (isUserInviteExpired(new Date(row.original?.inserted_at ?? new Date()))) {
											return (
												<Tooltip content={`User failed to respond, Resend option available`}>
													<p className='text-red-500'>Expired</p>
												</Tooltip>
											);
										}

										// User still has a valid invitation, but has not taken any action yet.
										if (row.original.inserted_at && !row.original.updated_at) {
											return (
												<Tooltip content={`Waiting for user to respond, no action needed`}>
													<p className='text-orange-500'>Pending</p>
												</Tooltip>
											);
										}
									},
								},
								{
									header: 'User',
									accessorFn: (row) => `${row.first_name} ${row.last_name}`,
									cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`,
								},
								{
									header: 'Email',
									accessorKey: 'email',
								},
								{
									header: 'Access Level',
									enableGlobalFilter: false,
									accessorKey: 'role',
									cell: ({ row }) => getRoleDesignationText(row.original.role),
								},

								{
									header: 'Facility Permissions',
									enableSorting: false,
									enableGlobalFilter: false,
									accessorKey: 'facilities',
									cell: ({ row }) => (
										<span className='truncate block w-32'>
											{row.original.facilities.reduce((prev, curr) => {
												if (curr.healthsystem_id === selectedSystem) {
													const facilityName = getFacilityName(facilities.find((f) => f.id === curr.id));

													if (facilityName) {
														return prev + (prev ? ', ' + facilityName : facilityName);
													}
												}
												return prev;
											}, '')}
										</span>
									),
								},
								{
									header: 'Default Facility',
									accessorKey: 'default_facility',
									enableGlobalFilter: false,
									cell: ({ row }) => {
										const facility = getFacility(facilities, row.original.default_facility);

										return (
											facility && (
												<span className='px-4 py-1 rounded-full border border-blue-500 bg-blue-100 text-blue-700 text-p3'>
													{getFacilityName(facility)}
												</span>
											)
										);
									},
								},
								{
									id: 'actions',
									meta: {
										bodyClass: 'text-right',
									},
									cell: ({ row }) => {
										const userId = row.original.id;
										const isResendInviteAvailable =
											!(!row.original.inserted_at || row.original.updated_at) &&
											isUserInviteExpired(new Date(row.original?.inserted_at ?? new Date()));
										const isPendingUserAction = !isResendInviteAvailable && row.original.inserted_at && !row.original.updated_at;

										return isAdministrator && row.original.role >= data.user.role ? (
											<div className='transition-opacity opacity-0 group-hover:opacity-100'>
												{isMerlinAdmin &&
													!isPendingUserAction &&
													(!isResendInviteAvailable ? (
														<>
															<ButtonLink
																onClick={async () => {
																	// ask the user to confirm the deletion
																	const alertResponse = await getAlertResponse({
																		title: 'Send user password reset email?',
																		description: 'Are you sure you want to send a password reset email to this user?',
																		responseOptions: [
																			{
																				value: 'send',
																				label: 'Send Email',
																			},
																		],
																	});

																	// handle the response from the user
																	if (alertResponse === 'send') {
																		sendResetEmail(row.original.email);
																	}
																}}
															>
																Reset
															</ButtonLink>
															<>{' | '}</>
														</>
													) : (
														<>
															<ButtonLink
																isWorking={isResending}
																onClick={async () => {
																	// ask the user to confirm the deletion
																	const alertResponse = await getAlertResponse({
																		title: 'Send user another invitation?',
																		description: 'Are you sure you want to send another invitation?',
																		responseOptions: [
																			{
																				value: 'send',
																				label: 'Send Email',
																			},
																		],
																	});

																	// handle the response from the user
																	if (alertResponse === 'send') {
																		resendInvite(row.original.id);
																	}
																}}
															>
																{!isResending && 'Resend'}
															</ButtonLink>
															<>{' | '}</>
														</>
													))}
												<UserSettingsDialog user={row.original} facilities={facilities}>
													<ButtonLink>Edit</ButtonLink>
												</UserSettingsDialog>{' '}
												|{' '}
												<ButtonLink
													isWorking={isDeleting && userBeingDeleted === userId}
													onClick={async () => {
														// ask the user to confirm the deletion
														const alertResponse = await getAlertResponse({
															title: 'Confirm User Deletion',
															description: 'Are you sure you want to delete this user?',
															responseOptions: [
																{
																	value: 'delete',
																	label: 'Delete',
																},
															],
														});

														// handle the response from the user
														if (alertResponse === 'delete') {
															// adds the loading indicator NEXT to the specific item being deleted
															setUserBeingDeleted(userId);
															const response = await deleteUser({
																id: userId,
															});
															handleApiResponse(response, {
																success: (payload) => {
																	toast.createToast({
																		title: payload.message,
																		variant: 'success',
																	});
																},
																error: (payload) => {
																	toast.createToast({
																		title: getQueryError(payload),
																		variant: 'error',
																	});
																},
															});
															// remove the loading indicator next to the user row of user being deleted
															setUserBeingDeleted(null);
														}
													}}
												>
													Delete
												</ButtonLink>
											</div>
										) : null;
									},
								},
							]}
							data={usersData?.users ?? []}
						/>
					</div>
				)}
			</div>

			<div className='mt-8'>
				<DataTable
					title='Facility License Details'
					tooltipContent={
						'Use this table to see how licenses have been applied across your account’s facilities, locations, or departments.'
					}
					goToHelpID='facilitylicense'
					columns={[
						{
							header: 'Facility',
							accessorKey: 'name',
							cell: ({ row }) => (
								<span className='px-3 py-1 text-p3 text-center font-semibold bg-blue-50 border border-blue-500 rounded-full'>
									{row.original.name}
								</span>
							),
							enableGlobalFilter: false,
						},
						{
							header: 'License',
							accessorKey: 'license',
							cell: ({ row }) => <span className='capitalize'>{getLicenseNames(row.original.license)}</span>,
							enableGlobalFilter: false,
						},
						{
							header: 'Room Licenses',
							accessorKey: 'roomsCount',
							enableGlobalFilter: false,
						},
					]}
					rowSubview={(row) => (
						<>
							{row.original.roomsCount > 0 ? (
								<table className='border-separate border-spacing-x-10 w-full'>
									<thead>
										<tr>
											<th className='pb-4 text-left text-bluegray-900 w-0'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Rooms Within {row.original.name}</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold'>Status</span>
											</th>
										</tr>
									</thead>

									<tbody>
										{row.original.rooms.map((room, i) => (
											<tr key={i}>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{room.name}</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>
													{room.licensed ? 'Licensed' : 'Unlicensed'}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<div className='text-p2 px-10'>This facility currently has no rooms.</div>
							)}
						</>
					)}
					data={facilityLicenseDetails}
				/>
			</div>
		</>
	);
}

export default AccountSettings;

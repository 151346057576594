import { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';

import { DataTable, FileDropzone, LogoOverlay, PageHeading, ToggleGroup, Tooltip } from 'components';
import { FacilityLicense, UserRole } from 'models';
import {
	getQueryError,
	handleApiResponse,
	useAppSelector,
	useGetFileQuery,
	useGetSystemsQuery,
	useReProcessUploadMutation,
	useSystem,
	useUploadFileMutation,
} from 'store';
import { checkLicenseBits, transformTimestamp, truncateLabel, userIs } from 'utils';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { useAlert, useToast } from 'context';
import { tabContentClassname } from 'pages/Block';

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300 whitespace-nowrap',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);

export function Upload() {
	const { createToast } = useToast();
	const [uploadFile, uploadResult] = useUploadFileMutation(undefined); // used to POST files
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);

	// Auth to use reprocess need to be core and admin
	const { data: authData } = useSystem();
	const { getAlertResponse } = useAlert();
	const isAdmin = userIs([UserRole.admin], authData?.user);
	const { data: healthsystem } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const facility = healthsystem?.facilities?.find((f) => f.id === selectedFacility);
	const isBlockFacility = checkLicenseBits(facility?.license, FacilityLicense.block);

	const [poll, setPoll] = useState<number | undefined>(30000); // 30 seconds
	const { data, isLoading, isFetching } = useGetFileQuery(
		{ facility_id: selectedFacility, healthsystem_id: selectedSystem },
		{
			pollingInterval: poll,
		}
	); // used to get uploaded files
	const [subViewRefreshing, setSubViewRefreshing] = useState<boolean>(false);

	const [reprocessUpload] = useReProcessUploadMutation();
	const [allowReprocess, setAllReprocess] = useState(true);
	// Uploads table will refresh if awaiting processing
	const areFilesProcessing = data ? data.files.filter((file) => file.status === null).length > 0 : false;
	const [uploadMode, setUploadMode] = useState<'intraop' | 'schedule' | 'delay_details'>('intraop');

	// stop polling if not awaiting processing
	useEffect(() => {
		if (areFilesProcessing) {
			setPoll(30000);
		} else {
			setPoll(undefined);
		}
	}, [areFilesProcessing]);

	// Uploads files to endpoints
	const handleUpload = async (droppedFiles: File[]) => {
		if (!selectedSystem) {
			createToast({
				title: 'Please select a Health System before uploading files.',
				variant: 'error',
			});
			return;
		}

		if (!selectedFacility) {
			createToast({
				title: 'Please select a facility before uploading files.',
				variant: 'error',
			});
			return;
		}

		if (droppedFiles.length > 5) {
			createToast({
				title: 'Only 5 files can be uploaded at once.',
				variant: 'error',
			});
			return;
		}

		for (const file of droppedFiles) {
			if (file.size >= 209715200) {
				createToast({
					title: `Upload failed for "${file.name}". Files must be less than 200MB.`,
					variant: 'error',
				});
				continue;
			}

			const formData = new FormData();
			formData.append('healthsystem_id', selectedSystem.toString());
			formData.append('facility_id', selectedFacility.toString());
			formData.append('type', uploadMode);
			formData.append('file', file);
			const response = await uploadFile(formData);

			handleApiResponse(response, {
				success: () => {
					createToast({
						title: `"${file.name}" was uploaded successfully.`,
						variant: 'success',
					});
				},
				error: (payload) => {
					createToast({
						title: getQueryError(payload),
						variant: 'error',
					});
				},
			});
		}
	};
	const { data: userData } = useSystem();
	const isHealthSystemUser = userData?.user.role === UserRole.facility_basic;
	const is_artificial_facility = !!facility?.name.includes('*');

	const handledeReprocessUpload = async (upload_id: number, delete_upload: boolean, success_message: string) => {
		const alertResponse = isBlockFacility
			? await getAlertResponse({
					description:
						'Reprocessing uploads causes surgeon names to be deleted and re-inserted, this can cause block patterns to have no surgeons assigned. All surgeons deleted from block patterns will need to be re-assigned manually. Are you sure you want to continue?',
					responseOptions: [
						{
							value: 'yes',
							label: 'Yes',
						},
					],
			  })
			: null;

		if (alertResponse === 'yes' || !isBlockFacility) {
			// don't allow reprocess until we get a response from server
			setAllReprocess(false);

			const response = await reprocessUpload({
				upload_id,
				delete: delete_upload,
			});

			handleApiResponse(response, {
				success: () => {
					createToast({
						title: success_message,
						variant: 'success',
					});
					setAllReprocess(true);
				},
				error: (payload) => {
					createToast({
						title: getQueryError(payload),
						variant: 'error',
					});
					setAllReprocess(true);
				},
			});
		}
	};

	return (
		<div>
			<PageHeading>File Management</PageHeading>

			<Tabs.Root
				defaultValue='intraop'
				orientation='vertical'
				className='mb-4'
				onValueChange={(e) => {
					setSubViewRefreshing(true);
					setUploadMode(e as typeof uploadMode);

					// this is used to force the rowsubviews to re-render
					setTimeout(() => {
						setSubViewRefreshing(false);
					}, 500);
				}}
			>
				<Tabs.List aria-label='intraop' className='flex border-b border-bluegray-100'>
					<Tabs.Trigger value='intraop' className={tabTriggerClassname}>
						Intraop Files
					</Tabs.Trigger>
					<Tabs.Trigger value='schedule' className={tabTriggerClassname}>
						Schedule Files
					</Tabs.Trigger>
					<Tabs.Trigger value='delay_details' className={tabTriggerClassname}>
						Delay Files
					</Tabs.Trigger>
				</Tabs.List>
			</Tabs.Root>

			{isHealthSystemUser && (
				<div className='flex items-center text-sm text-blue-500 font-semibold mb-2'>
					<span className='material-symbol mr-2'>info</span>
					<span className='font-secondary'>{'As a Health System User you are not allowed to perform this operation.'}</span>
				</div>
			)}
			{is_artificial_facility && (
				<div className='flex items-center text-sm text-blue-500 font-semibold mb-2'>
					<span className='material-symbol mr-2'>info</span>
					<span className='font-secondary'>{'Uploads are not allowed for this facility.'}</span>
				</div>
			)}
			<FileDropzone
				dropFileFn={handleUpload}
				mode={uploadMode}
				isLoading={uploadResult.status === 'pending'}
				disabled={isHealthSystemUser || is_artificial_facility}
			/>

			<div className='mt-14'>
				{isLoading && <LogoOverlay backgroundColor='white' />}
				<DataTable
					title='File Uploads'
					tooltipContent={'Use this table to see data that has been uploaded into Merlin.'}
					goToHelpID='uploads'
					data={data?.files.filter((row) => row.file_type === uploadMode) ?? []}
					columns={[
						{
							header: 'Status',
							accessorKey: 'status',
							cell: ({ row }) => {
								const status = row.getValue<boolean | null>('status');
								switch (status) {
									case true:
										return <p className='font-semibold text-p2 text-green-500'>Successfully Processed</p>;
									case false:
										return <p className='font-semibold text-p2 text-red-500'>Process Failure</p>;
									case null:
										return (
											<div className='flex items-center'>
												<div className='h-4 w-4 mr-1'>
													<LoadingIndicator />
												</div>

												<p className='font-semibold text-p2 text-blue-500 mt-0.5'>Processing</p>
											</div>
										);
									default:
										return '';
								}
							},
							enableGlobalFilter: false,
						},
						{
							header: 'Date of Upload',
							accessorKey: 'date_of_upload',
							sortDescFirst: true,
							accessorFn: (row) => transformTimestamp(row.date_of_upload),
							sortingFn: (rowA, rowB, columnId) => {
								const a = new Date(rowA.getValue(columnId)).getTime();
								const b = new Date(rowB.getValue(columnId)).getTime();
								return b > a ? 1 : -1;
							},
							enableGlobalFilter: false,
						},
						{ header: 'Name of File', accessorKey: 'filename' },
						{ header: 'Uploaded By', accessorKey: 'uploaded_by' },
						{
							header: 'File Size',
							accessorKey: 'file_size',
							accessorFn: (row) => `${(parseInt(row.file_size) / 1000).toFixed(2)}MB`,
							enableGlobalFilter: false,
						},
						//
						{
							header: 'File Type',
							cell: ({ row }) => {
								return (
									<div className='flex justify-between items-end'>
										<p className='mr-2 w-16'>{row.original.file_type}</p>
										{isAdmin && (row.original.status === false || row.original.status === true) && (
											<div className='w-32 flex justify-between'>
												<Tooltip content='Delete all records from this upload and reinsert records.'>
													<p
														onClick={() => {
															if (allowReprocess) {
																handledeReprocessUpload(row.original.upload_id, false, 'Reprocessing has started.');
															}
														}}
														className='text-blue-500 text-p3 cursor-pointer underline whitespace-nowrap'
													>
														{allowReprocess ? (
															'Reprocess'
														) : (
															<div className='text-gray-500 w-12 flex justify-center'>
																<LoadingIndicator />
															</div>
														)}
													</p>
												</Tooltip>
												<Tooltip content='Delete all records from this upload.'>
													<p
														onClick={() => {
															if (allowReprocess) {
																handledeReprocessUpload(row.original.upload_id, true, 'File successfully deleted.');
															}
														}}
														className='text-red-500 text-p3 cursor-pointer underline whitespace-nowrap pr-3'
													>
														{allowReprocess ? (
															'Delete'
														) : (
															<div className='text-gray-500 w-12 flex justify-center'>
																<LoadingIndicator />
															</div>
														)}
													</p>
												</Tooltip>
											</div>
										)}
									</div>
								);
							},
						},
					]}
					rowSubview={
						isFetching || subViewRefreshing ? (
							<div className='flex items-center'>
								<div className='h-4 w-4 m-auto'>
									<LoadingIndicator />
								</div>
							</div>
						) : (
							(row) => {
								return row.getIsSelected()
									? data?.files && <FidelityModule metadata={row.original.metadata} filename={row.original.filename} />
									: 'Loading...';
							}
						)
					}
				/>
			</div>
		</div>
	);
}

interface FidelityModuleProps {
	metadata: {
		cases_success_count: number;
		facility_name: string;
		upload_id: number;
		message: string;
		status: boolean | null;
		issues: { name: string; value: number }[];
		total_cases_count: number;
		unrecognized_surgeons_count: number;
		unrecognized_rooms_count: number;
		unrecognized_service_lines_count: number;
		unrecognized_encounter_types_count: number;
		scheduled_start_missing_count: number;
		duration_error_count: number;
	}[];
	filename: string;
}

// fidelity report - shown at bottom of each upload sds
function FidelityModule({ filename, metadata }: FidelityModuleProps) {
	const [selectOptions, setSelectionOptions] = useState(
		metadata.map((data, id) => ({ ...data, index: id, selected: id < 4 }))
	);

	const selectedFacilites = selectOptions.filter((element) => element.selected);

	const totalCases = selectOptions
		.map((element) => (element.status ? element.total_cases_count : 0))
		.reduce((partialSum, a) => partialSum + a, 0);

	const totalSuccess = selectOptions
		.map((element) => (element.status ? element.cases_success_count : 0))
		.reduce((partialSum, a) => partialSum + a, 0);

	const topIssuesMapping: { [key: string]: number } = {};

	// Cumulative sum of issues
	for (let i = 0; i < selectOptions.length; i++) {
		for (let j = 0; j < selectOptions[i].issues.length; j++) {
			if (topIssuesMapping[selectOptions[i].issues[j].name]) {
				topIssuesMapping[selectOptions[i].issues[j].name] =
					topIssuesMapping[selectOptions[i].issues[j].name] + selectOptions[i].issues[j].value;
			} else {
				topIssuesMapping[selectOptions[i].issues[j].name] = selectOptions[i].issues[j].value;
			}
		}
	}

	return (
		<div className='flex-col'>
			<div className='flex justify-left'>
				{selectOptions.length > 1 && (
					<div className='flex-col bg-white p-2 pb-4 rounded-sm mr-4 border-blue-400 border-2'>
						<div>
							<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mb-2'>
								Overall Upload Fidelity Metrics
							</p>
							<div className='flex w-[17em] justify-between bg-blue-50 p-1 rounded-sm px-2'>
								<div className='flex flex-col font-semibold'>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>Ingested</p>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>
										{totalCases > 0 ? Math.round((totalSuccess / totalCases) * 100 * 10) / 10 : '-'}
										{totalCases > 0 && '%'}
									</p>
								</div>
								<div className='flex flex-col'>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>Total Records</p>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>{totalCases ? totalCases : '-'}</p>
								</div>
								<div className='flex flex-col'>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>Included</p>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>{totalSuccess ? totalSuccess : '-'}</p>
								</div>
								<div className='flex flex-col'>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>Excluded</p>
									<p className='whitespace-nowrap font-secondary text-[0.75em]'>
										{totalCases - totalSuccess ? totalCases - totalSuccess : '-'}
									</p>
								</div>
							</div>
						</div>

						<div className='mb-1'>
							<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mt-3 mb-1'>
								Facilities {selectedFacilites.length > 0 ? ` - (Selected: ${selectedFacilites.length})` : ''}
							</p>
							<div className='max-h-[10em] overflow-y-scroll'>
								{selectOptions.map((option, option_index) => (
									<div key={option_index}>
										<div
											className={classNames(
												'flex mr-1 p-1 pb-1.5 justify-left mb-1 items-center hover:bg-blue-50 cursor-pointer',
												option.selected && 'bg-blue-50'
											)}
											onClick={() => {
												setSelectionOptions(
													selectOptions.map((element) => {
														const temp = element;
														if (temp.index === option_index) {
															temp.selected = !temp.selected;
														}
														return temp;
													})
												);
											}}
										>
											<p className='whitespace-nowrap font-secondary mr-1'>
												{' '}
												{option.status === true && (
													<span className='material-symbol text-green-500'>
														<span className='material-symbols-outlined text-[1rem]'>check_circle</span>
													</span>
												)}
												{option.status === null && (
													<span className='material-symbol text-blue-500'>
														<span className='material-symbols-outlined text-[1rem]'>do_not_disturb_on</span>
													</span>
												)}
												{option.status === false && (
													<span className='material-symbol text-red-500'>
														<span className='material-symbols-outlined text-[1rem]'>do_not_disturb_off</span>
													</span>
												)}
											</p>
											<p className='whitespace-nowrap font-secondary text-[0.85em] capitalize mt-1'>
												{truncateLabel(option.facility_name, 30)}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{selectOptions.length > 0 && (
					<div className='flex overflow-x-auto'>
						{selectOptions
							.filter((element) => element.selected)
							.map((meta, index) => {
								return (
									<div key={index} className='flex-col bg-white p-2 rounded-sm mr-2 mb-2 drop-shadow-sm'>
										<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mb-1'>Facility Name</p>
										<div className='flex justify-between items-center mr-2'>
											<p className='whitespace-nowrap font-semibold'>{truncateLabel(meta.facility_name, 27)}</p>
											{meta.status === true && <span className='material-symbol text-green-500'>check_circle</span>}
											{meta.status === null && (
												<span className='material-symbol text-blue-500'>
													<span className='material-symbols-outlined'>do_not_disturb_on</span>
												</span>
											)}
											{meta.status === false && (
												<span className='material-symbol text-red-500'>
													<span className='material-symbols-outlined'>do_not_disturb_off</span>
												</span>
											)}
										</div>

										{meta.status !== null && meta.status !== false && (
											<div>
												<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mt-2 mb-1'>
													Facility Fidelity Metrics
												</p>
												<div className='flex w-[17em] justify-between bg-gray-50 p-1 rounded-sm px-2'>
													<div className='flex flex-col font-semibold'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Ingested</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.total_cases_count > 0
																? Math.round((meta.cases_success_count / meta.total_cases_count) * 100 * 10) / 10
																: '-'}
															{meta.total_cases_count > 0 && '%'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Total Records</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.total_cases_count ? meta.total_cases_count : '-'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Included</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.cases_success_count ? meta.cases_success_count : '-'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Excluded</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.total_cases_count - meta.cases_success_count
																? meta.total_cases_count - meta.cases_success_count
																: '-'}
														</p>
													</div>
												</div>
												<div className='flex w-[17em] justify-between bg-gray-50 p-1 rounded-sm px-2 mt-2'>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>New Surgeons</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.unrecognized_surgeons_count ? meta.unrecognized_surgeons_count : '-'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>New Service Lines</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.unrecognized_service_lines_count ? meta.unrecognized_service_lines_count : '-'}
														</p>
													</div>
												</div>
												<div className='flex w-[17em] justify-between bg-gray-50 p-1 rounded-sm px-2 mt-2'>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>New Encounter Types</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.unrecognized_encounter_types_count ? meta.unrecognized_encounter_types_count : '-'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>New Room Names</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.unrecognized_rooms_count ? meta.unrecognized_rooms_count : '-'}
														</p>
													</div>
												</div>
												<div className='flex w-[17em] justify-between bg-gray-50 p-1 rounded-sm px-2 mt-2'>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Sched. Starts Missing</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.scheduled_start_missing_count ? meta.scheduled_start_missing_count : '-'}
														</p>
													</div>
													<div className='flex flex-col'>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>Duration Exclusions</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>
															{meta.duration_error_count ? meta.duration_error_count : '-'}
														</p>
													</div>
												</div>
											</div>
										)}
										{meta.issues.length > 0 && (
											<div className='mb-1'>
												<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mt-3 mb-1'>
													Top {meta.issues.length} Issues
												</p>
												{meta.issues.map((issue, issue_index) => (
													<div className='flex justify-between mr-4 mb-1' key={issue_index}>
														<p className='whitespace-nowrap font-secondary text-[0.75em] capitalize'>
															{issue.name.replace('_count', '').replaceAll('_', ' ')}
														</p>
														<p className='whitespace-nowrap font-secondary text-[0.75em]'>{issue.value}</p>
													</div>
												))}
											</div>
										)}
										{(meta.status === null || meta.status === false) && (
											<div className='mb-2 w-[17em]'>
												<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 mt-3 mb-1 w-[17em]'>
													Processing Message
												</p>
												<p className='font-secondary text-[0.75em]'>{meta.message}</p>
											</div>
										)}
									</div>
								);
							})}
					</div>
				)}
			</div>
			{Object.entries(topIssuesMapping).length > 1 && selectOptions.length > 1 && (
				<div className='mt-4'>
					<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 capitalize mb-1'>
						Most Common Issues Found
					</p>{' '}
					<div className='flex w-fit justify-between p-1 pl-0 rounded-sm px-2 max-w-[74em] overflow-x-auto'>
						{Object.entries(topIssuesMapping)
							.map((val) => ({ name: val[0], value: val[1] }))
							.map((issue, issue_index) => (
								<div className='flex flex-col mr-3 py-2 bg-white px-2 rounded-md drop-shadow-sm' key={issue_index}>
									<p className='whitespace-nowrap font-secondary text-[0.85em] text-right'>{issue.value}</p>
									<p className='whitespace-nowrap font-secondary text-[0.75em] text-gray-500 capitalize'>
										{issue.name.replace('_count', '').replaceAll('_', ' ')}
									</p>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
}

export default Upload;

import { DAYS_OF_WEEK, DAYS_OF_WEEK_LIST, MONTHS } from 'utils';

/**
 * Get a formatted string representing "today's" date.
 * @returns string
 */
export function now() {
	return new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

/**
 * A function to return the time in a format that our python backend can easily parse (HH:MM:SS) (e.g. 07:42:00)
 * @param d the date where time is being extracted from
 */
export function toMilitaryTime(d: Date, dropSeconds?: boolean) {
	if (dropSeconds) {
		d.setSeconds(0);
	}

	return d.toLocaleString('en-US', {
		timeStyle: 'medium',
		hour12: false,
	});
}

/**
 * A function to return the time in standard format that humans are accustomed to reading.
 * @param d the date where time is being extracted from
 */
export function toStandardTime(d: Date, style?: 'short' | 'medium') {
	return d.toLocaleString('en-US', {
		timeStyle: style ?? 'medium',
		hour12: true,
	});
}

/**
 * Utility function to "clone" a data object
 * @param d date to clone
 */
export function clone(d: Date) {
	return new Date(d.getTime());
}

/**
 * Utility function to get the first day of the week relative to another date
 * @param d gets the date of the Sunday that occurred prior to the date passed as argument
 */
export function getFirstDateOfWeek(d: Date): Date {
	const nd = new Date(d);
	return new Date(nd.setDate(nd.getDate() - nd.getDay()));
}

/**
 * Utility function that tells if two dates occur on the exact same day
 * @param d1 the first date
 * @param d2 the second date
 */
export function isSameDay(d1: Date | null, d2: Date | null): boolean {
	if (d1 && d2)
		return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

	return false;
}

/**
 * Utility function to convert date string in formate mm/dd/yyyy
 * @param date the date to be formatted
 */
export function toFullDate(date: Date): string {
	const d = new Date(date);
	return new Intl.DateTimeFormat('en-US').format(d);
}

/**
 * Utility function to get the next day/date, relative to the date passed in argument
 * @param date the date to start from
 * @returns the day after date argument
 */
export function getNextDate(date: Date): Date {
	const tomorrow = new Date(date);
	tomorrow.setDate(date.getDate() + 1);
	return tomorrow;
}

/**
 * Utility function to get the next month, relative to the date passed in argument
 * @param date the date to start from
 * @returns the day after date argument
 */
export function getNextMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

/**
 * Utility function to get the previous month, relative to the date passed in argument
 * @param date the date to start from
 * @returns the day after date argument
 */
export function getPreviousMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

/**
 * Utility function to get the previous day/date, relative to the date passed in argument
 * @param date the date to start from
 * @returns the day before date argument
 */
export function getPreviousDate(date: Date): Date {
	const yesterday = new Date(date);
	yesterday.setDate(date.getDate() - 1);
	return yesterday;
}

/**
 * Utility function that converts timestamp string e.g. (YYYY-MM-DD HH:MM:SS) to (MM/DD/YYYY, HH:MM:SS AM|PM) format that can be used with the DataTable.
 * Returns date in local time.
 * @param stringTime string of timestamp e.g. (YYYY-MM-DD HH:MM:SS)
 * @returns Date
 */
export function transformTimestamp(stringTime: string) {
	return new Date(stringTime).toLocaleString('en-US');
}

/**
 * Utility function that converts a number e.g. 1, 2, 3 to day of week by name (Sun/Sunday).
 * Returns string.
 * @param dowNumeric number representing a day of week (1 - Sunday, 2 - Monday, etc.)
 * @param format specifies whether to use short or long format of dow names
 * @returns The converted day of week name as a string
 */
export function convertDOWNumericToString(dowNumeric: number, format?: string) {
	if (dowNumeric < 1 || dowNumeric > 7) {
		throw new Error('convertDOWNumericToString requires a number between 1 and 7');
	}

	if (format === 'short') {
		return DAYS_OF_WEEK_LIST[dowNumeric - 1].slice(0, 3);
	}

	return DAYS_OF_WEEK_LIST[dowNumeric - 1];
}

/**
 * Utility function that converts a short DOW to DOW Number e.g. 'Mon', 'Tue', 'Wed' to day of week by number (0,1,2).
 * Returns number.
 * @param shortDow string representing a day of week (Sun,Mon, etc.)
 * @returns The converted day of week string as a number
 */
export function shortDOWToNumber(shortDow: string) {
	return DAYS_OF_WEEK_LIST.findIndex((d) => d.slice(0, 3) === shortDow);
}

/**
 * Utility function that converts date string e.g. (YYYY-MM-DD) to month by name e.g. Jan, Feb, Mar.
 * Returns string.
 * @param stringDate string of date e.g. (YYYY-MM-DD)
 * @returns string
 */
export function getNameOfMonth(stringDate: string) {
	const [year, month, day] = stringDate.split('-');
	const numMonth = parseInt(month) - 1;
	const date = new Date(parseInt(year), numMonth, parseInt(day));
	return date.toLocaleString('en-us', { month: 'short' });
}

/**
 * Utility function that converts a number e.g. 1, 2, 3 to month by name (Jan/January).
 * Returns string.
 * @param monthNumeric number representing a month (1 - Sunday, 2 - Monday, etc.)
 * @param format specifies whether to use short or long format of month names
 * @returns The converted month name as a string
 */
export function convertMonthNumericToString(monthNumeric: number, format?: string) {
	if (monthNumeric < 1 || monthNumeric > 12) {
		throw new Error('convertMonthNumericToString requires a number between 1 and 12');
	}

	if (format === 'short') {
		return MONTHS[monthNumeric - 1].slice(0, 3);
	}

	return MONTHS[monthNumeric - 1];
}

/**
 * Utility function that converts date string e.g. (YYYY-MM-DD) to last two digits of year e.g. 2019 -> '19'.
 * Returns string.
 * @param stringDate string of date e.g. (YYYY-MM-DD)
 * @returns string
 */
export function getShortYear(stringDate: string) {
	return (new Date(stringDate).getUTCFullYear() % 100).toString();
}

/**
 * Utility function to convert time as string e.g. (HH:MM:SS) to a Date object. Used when undefined input possible
 * @param timeString time as string e.g. (HH:MM:SS)
 * @returns Date | undefined
 */
export function stringTimeToDate(timeString: string | undefined) {
	const date = new Date();
	if (timeString) {
		const [hour, minute] = timeString.split(':');
		date.setHours(parseInt(hour));
		date.setMinutes(parseInt(minute));
		date.setSeconds(0);
	}

	return date;
}

/**
 * Utility function that returns sequence of dates until saturday.
 * Returns string[] in YYYY-MM-DD format.
 * @param date Date object to start sequence of dates from
 * @returns string[]
 */
export function getWeekEndSeq(date: Date) {
	const dates = [];
	const dateClone = new Date(date.toLocaleDateString());
	date = getWeekStart(dateClone);

	while (date.getDay() !== 6) {
		dates.push(date.toISOString().split('T')[0]);
		date.setDate(date.getDate() + 1);
	}
	dates.push(date.toISOString().split('T')[0]);
	return dates;
}

/**
 * Utility function that returns beginning of week
 * Returns string in YYYY-MM-DD format.
 * @param date Date object to start sequence of dates from
 * @returns string
 */
export function getWeekStart(date: Date) {
	while (date.getDay() !== 0) {
		date.setDate(date.getDate() - 1);
	}
	return date;
}

/**
 * Utility function that converts date string e.g. (YYYY-MM-DD) to day of week by name e.g. Mon, Tue, Wed.
 * Returns string.
 * @param stringDate string of date e.g. (YYYY-MM-DD)
 * @returns string
 */
export function getNameOfDay(stringDate: string) {
	const dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
	stringDate = stringDate.replace('-', '/');
	return dow[new Date(stringDate).getDay()];
}

/**
 * Utility function that converts date string e.g. (YYYY-MM-DD) to day of week by name e.g. Mon, Tue, Wed.
 * Returns string.
 * @param stringDate string of date e.g. (YYYY-MM-DD)
 * @returns string
 */
export function getDowNameFromNumber(index: number) {
	const dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
	return dow[index - 1];
}
/**
 * Utility function that increase date by years
 * Returns string.
 * @param date Date type
 * @param year Number type
 * @returns date increased
 */
export function increaseDateByYear(date: Date, year: number) {
	return new Date(date.getFullYear() + year, date.getMonth(), date.getDate());
}

/**
 * Utility function that converts date to UTC Date.
 * Returns Date.
 * @param date type of Date
 * @returns UTC-Date
 */
export function convertToUTC(date: Date) {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

/**
 * Utility function that mirrors a date range relative to a new latest date.
 * Returns a new startDate.
 */
export function getShiftedStartDate(startDate: Date, endDate: Date, latestDate: Date) {
	const timeDiff = endDate.getTime() - startDate.getTime();
	const newStart = latestDate.getTime() - timeDiff;
	return new Date(newStart);
}

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/** Sorts an array of day of week names
 * **/
export const sortDays = function (a: DAYS_OF_WEEK, b: DAYS_OF_WEEK) {
	const x = days.indexOf(a);
	const y = days.indexOf(b);
	return x - y;
};

/** Utility function to convert an int to a hours and minutes representation
 * Returns string.
 */
export function minutesToHours(minutes: number) {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	return `${hours}h ${remainingMinutes}m`;
}

/** Utility function that returns all dates between two dates.
 * Returns string.
 */
export function getDatesBetween(startDate: string, endDate: string) {
	const start = new Date(startDate);
	const end = new Date(endDate);
	const dates = [];
	while (start <= end) {
		dates.push(start.toISOString().split('T')[0]);
		start.setDate(start.getDate() + 1);
	}
	return dates;
}

/** Utility function that converts slashed date M/D/YYYY to YYYY-MM-DD.
 * Returns string.
 */
export function convertDateFormat(input: string): string {
	// Parse the input string to a Date object
	const date = new Date(input);

	// Extract the year, month, and day
	const year = date.getFullYear();
	const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-indexed in JavaScript
	const day = ('0' + date.getDate()).slice(-2);

	// Format the date as 'YYYY-MM-DD'
	return `${year}-${month}-${day}`;
}

/**
 * Converts minutes from midnight to military time format.
 *
 * @param {number} minutes - The total minutes from midnight.
 * @returns {string} The time in military format (HHMM).
 *
 * @example
 * // Returns "0230"
 * convertMinutesToMilitaryTime(150);
 */
export function convertMinutesToMilitaryTime(minutes: number): string {
	const hours = Math.floor(minutes / 60);
	const minutesRemaining = minutes % 60;
	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutesRemaining.toString().padStart(2, '0');
	return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Converts a given number of seconds into a human-readable time format (HH:MM:SS).
 * If the total seconds exceed one hour, hours will be included in the output.
 *
 * @param {number|string} s - The number of seconds to convert. Can be a number or a string representing a number.
 * @returns {string} A string representation of the time in HH:MM:SS format.
 *
 * Example:
 * ```


*/
export function formatSecondsToTime(s: number | string): string {
	const totalSeconds = typeof s === 'number' ? s : parseInt(s, 10);

	const hours = Math.floor(totalSeconds / 3600);
	const remainingSeconds = totalSeconds % 3600;
	const minutes = Math.floor(remainingSeconds / 60);
	const seconds = remainingSeconds % 60;

	return `${hours}:${minutes}:${seconds}`;
}

/**
 * Calculates the difference in days between today's date and a given target date.
 *
 * @param {string} date - The target date as a string (e.g., '2025-02-10').
 * @returns {number} - The number of days from today to the target date.
 *                     If the target date is in the future, the result is positive;
 *                     if it's in the past, the result is negative.
 */
export function daysDifference(targetDate: Date): number {
	// Get the current date
	const today: Date = new Date();

	// Calculate the time difference between the target date and today in milliseconds
	const timeDifference: number = today.getTime() - targetDate.getTime();

	// Convert the time difference from milliseconds to days
	const daysDifference: number = timeDifference / (1000 * 3600 * 24);

	// Return the difference rounded down to the nearest whole number
	return Math.floor(daysDifference);
}
